import { memo, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { AppMap, AppMapFacade } from '@@map/AppMap';
import { vikingPin, vismanIcon } from '@@map/pins';
import { AppLatLng, AppMarker, AppPolyline } from '@@map/types';
import { DebugOnly } from '@@shared/utils/DebugOnly';
import { INCIDENT_DEFAULT_LOCK_ON_ZOOM, DEFAULT_MAP_ZOOM } from '@@shared/utils/constants';
import { getCssColorVariable } from '@@shared/utils/style';

interface Props {
  incidentLocation: AppLatLng | undefined;
  vikingCarLocation: AppLatLng | undefined;
  encodedPolyline: string | undefined;
  mapCenter: AppLatLng | undefined;
}

const defaultMapCenter: AppLatLng = {
  lat: 59.93050287143177,
  lng: 10.711509612434527,
};

const TrackMapComp = (props: Props) => {
  const { incidentLocation, vikingCarLocation, encodedPolyline, mapCenter } = props;
  const [facade, setFacade] = useState<AppMapFacade>();

  const [hasFocusedRoute, setHasFocusedRoute] = useState(false);

  const markers = useMemo<AppMarker[]>(() => {
    const markers: AppMarker[] = [];

    if (incidentLocation) {
      markers.push({
        id: 'incidentLocation',
        latitude: incidentLocation.lat,
        longitude: incidentLocation.lng,
        icon: { url: vikingPin, width: 20, height: 32 },
      });
    }

    if (vikingCarLocation) {
      markers.push({
        id: 'vikingCarLocation',
        latitude: vikingCarLocation.lat,
        longitude: vikingCarLocation.lng,
        icon: { url: vismanIcon, width: 32, height: 32 },
      });
    }

    return markers;
  }, [incidentLocation, vikingCarLocation]);

  useEffect(() => {
    if (!facade || !incidentLocation) {
      return;
    }
    const currentCenter = facade.getCenter();
    const incidentLocationHasChanged =
      currentCenter?.lat !== incidentLocation.lat || currentCenter?.lng !== incidentLocation.lng;

    if (!vikingCarLocation && incidentLocationHasChanged) {
      facade.setCenter(incidentLocation);
      facade.setZoom(INCIDENT_DEFAULT_LOCK_ON_ZOOM);
      setHasFocusedRoute(false);
    }
    if (vikingCarLocation && !hasFocusedRoute) {
      setHasFocusedRoute(true);
      facade.fitBounds([vikingCarLocation, incidentLocation]);
    }
  }, [incidentLocation, facade, hasFocusedRoute, vikingCarLocation]);

  const handleMapLoaded = (facade: AppMapFacade) => {
    setFacade(facade);
    // seems this needs to be reset
    setHasFocusedRoute(false);
  };

  const polyline = useMemo(() => {
    const appPolyline: AppPolyline | undefined = encodedPolyline
      ? { encodedPath: encodedPolyline, color: getCssColorVariable('brand-primary') }
      : undefined;

    return appPolyline;
  }, [encodedPolyline]);
  return (
    <ErrorBoundary>
      <div className="relative">
        <AppMap
          markers={markers}
          initZoom={DEFAULT_MAP_ZOOM}
          initCenter={mapCenter || defaultMapCenter}
          polyline={polyline}
          containerClassName="h-[50vw] max-h-[30rem] min-h-[20rem]"
          onMapLoaded={handleMapLoaded}
        />
        <DebugOnly>
          <div className="absolute right-0 top-0 bg-gray-50 p-1">Track map</div>
        </DebugOnly>
      </div>
    </ErrorBoundary>
  );
};

export const TrackMap = memo(TrackMapComp);
