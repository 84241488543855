import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@@shared/base/Card';

const LocateConcludedContainerComp = () => {
  const { t } = useTranslation();

  return (
    <div className="p-2 pt-4">
      <Card>
        <div className="flex flex-col items-center justify-center">
          <div className="pb-2 pt-4 text-center text-xl">{t('caseConcludedLocate')}</div>
          <div className="pt-4">{t('youCanClosePage')}</div>
        </div>
      </Card>
    </div>
  );
};

export const LocateConcludedContainer = memo(LocateConcludedContainerComp);
