import { geoCircle } from '@@map/pins';
import { AppGeoCoordinates, AppCircle, AppMarker } from '@@map/types';

export function buildAccuracyCircle(deviceGeoCoordinates: AppGeoCoordinates | undefined): AppCircle | undefined {
  return deviceGeoCoordinates
    ? {
        id: ' deviceGeoAccuracyCircle',
        latitude: deviceGeoCoordinates.lat,
        longitude: deviceGeoCoordinates.lng,
        radius: deviceGeoCoordinates.accuracy,
      }
    : undefined;
}

export function buildDeviceCoordsMarker(deviceGeoCoordinates: AppGeoCoordinates | undefined): AppMarker | undefined {
  return deviceGeoCoordinates
    ? {
        id: 'deviceCoordinatesMarker',
        latitude: deviceGeoCoordinates.lat,
        longitude: deviceGeoCoordinates.lng,
        icon: { url: geoCircle, width: 24, height: 24, isCircle: true },
      }
    : undefined;
}
