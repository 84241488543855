import { memo } from 'react';
import { MapPin, Phone } from '@@shared/icons';

interface DestinationComponentProps {
  name: string | undefined;
  address: string | undefined;
  postalCode: string | undefined;
  postalTown: string | undefined;
  phoneNumber: string | undefined;
}

const DestinationComponent = (props: DestinationComponentProps) => {
  const { name, address, postalCode, postalTown, phoneNumber } = props;

  return (
    <div className="flex gap-4">
      <div>
        <MapPin className="mt-1 text-brand-primary" />
      </div>
      <div className="grow">
        <p className="text-lg font-bold">{name}</p>
        <p>{address}</p>
        <p>
          {postalCode} {postalTown}
        </p>
        {phoneNumber ? <p className="hidden md:block">{phoneNumber}</p> : null}
      </div>
      {phoneNumber ? (
        <div className="flex items-center md:hidden">
          <a
            className="rounded-full border-2 border-brand-primary p-2 hover:cursor-pointer"
            href={`tel:${phoneNumber}`}
          >
            <Phone className="text-brand-primary" />
          </a>
        </div>
      ) : null}
    </div>
  );
};

export const Destination = memo(DestinationComponent);
