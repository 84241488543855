import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { GGeocoder } from './googleTypes';
import { AppLatLng } from './types';
import { useGoogleScriptLoader } from './useGoogleScriptLoader';
import { toGLatLng, toPreciseEnough } from './utils';

export const useReverseGeocoding = (coords: AppLatLng | undefined) => {
  const geocoder = useRef<GGeocoder | undefined>();

  const coordsQueryKey = coords ? `${toPreciseEnough(coords.lat)},${toPreciseEnough(coords.lng)}` : undefined;

  const { isLoaded } = useGoogleScriptLoader();

  return useQuery(
    ['reverseGeocoding', coordsQueryKey],
    async () => {
      if (!coords) return;

      if (!geocoder.current) geocoder.current = new google.maps.Geocoder();

      const geocodeResult = await geocoder.current.geocode({ location: toGLatLng(coords) });

      if (!geocodeResult?.results.length) return;

      return geocodeResult.results[0].formatted_address;
    },
    { enabled: isLoaded && !!coords, keepPreviousData: true, staleTime: Infinity }
  );
};
