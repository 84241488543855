import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InitialCaseDataResponse } from '@@api/generated/commonTypes';
import { appConfig } from '@@config/appConfig';
import { appInsights } from '@@config/appInsights';
import { V_LANGUAGE_CODE_TO_ISO1 } from '@@shared/utils/constants';

export const useLanguage = (initialCaseData: InitialCaseDataResponse | undefined) => {
  const { i18n } = useTranslation();

  const languageCode =
    V_LANGUAGE_CODE_TO_ISO1[initialCaseData?.preferredLanguage?.value ?? ''] ?? appConfig.defaultLanguage;

  useEffect(() => {
    if (!initialCaseData) {
      return;
    }
    if (i18n.language !== languageCode) {
      i18n.changeLanguage(languageCode, (error) => {
        if (error) {
          const oneError = Array.isArray(error) && !!error.length ? error[0] : error;
          appInsights.trackException({ exception: oneError }, { languageCode });
        }
      });
    }
  }, [i18n, languageCode, initialCaseData]);
};
