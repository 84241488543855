import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RpcError } from '@@api/rpcExports';
import { ERROR_MISSING_SECRET } from '@@auth/authenticationQueries';
import { Disclosure } from '@@shared/base/Disclosure';
import { XCircle } from '@@shared/icons';
import { errorMessageCodes } from '@@shared/utils/error';
import { FullSizeError } from './FullSizeError';

interface Props {
  error: RpcError | Error | unknown;
  title?: string;
  isApiError?: boolean;
  fullPageError?: boolean;
}

interface ApiErrorMapping {
  grpcCode: GrpcStatusCode;
  httpCode: number;
  titleTranslationKey: string;
  descriptionTranslationKey: string;
  condition?: (message: string) => boolean;
}

const apiErrorsMapping: ApiErrorMapping[] = [
  {
    grpcCode: GrpcStatusCode.NOT_FOUND,
    httpCode: 404,
    titleTranslationKey: 'notFoundTitle',
    descriptionTranslationKey: 'notFoundDescription',
  },
  {
    grpcCode: GrpcStatusCode.UNAUTHENTICATED,
    httpCode: 401,
    titleTranslationKey: 'keyExpiredTitle',
    descriptionTranslationKey: 'keyExpiredDescription',
    condition: (message: string) => message.toLowerCase().includes(errorMessageCodes.expiredAuthCode),
  },
  {
    grpcCode: GrpcStatusCode.UNAUTHENTICATED,
    httpCode: 401,
    titleTranslationKey: 'keyInvalidTitle',
    descriptionTranslationKey: 'keyInvalidDescription',
    condition: (message: string) => message.toLowerCase().includes(errorMessageCodes.invalidAuthCode),
  },
];

const ErrorPageComp = (props: Props) => {
  const { error, title, isApiError, fullPageError } = props;

  const { t } = useTranslation();
  const errorMessage = (error as Error)?.message;

  const missingSecret = errorMessage === ERROR_MISSING_SECRET;
  const rpcCode = (error as RpcError)?.code as keyof typeof GrpcStatusCode;
  const code = missingSecret ? GrpcStatusCode.NOT_FOUND : rpcCode ? GrpcStatusCode[rpcCode] : undefined;
  const apiError = apiErrorsMapping.find(
    (e) => e.grpcCode === code && (e.condition ? e.condition(errorMessage) : true)
  );

  if (fullPageError && apiError) {
    return (
      <FullSizeError
        code={apiError.httpCode}
        description={t(apiError.descriptionTranslationKey)}
        title={t(apiError.titleTranslationKey)}
      />
    );
  }

  return (
    <div className="flex h-full flex-col items-center justify-center  pb-8 text-center">
      <XCircle className="h-10 w-10 pb-2 text-vk-red" />
      <div className="text-xl">{title || t('tryAgainErrorMessage')}</div>
      <div className="pb-2">{t('errorPersistMessage')}</div>
      {(error as Error)?.message ? (
        <Disclosure title={t('moreDetails')}>{isApiError ? decodeURI(errorMessage) : errorMessage}</Disclosure>
      ) : null}
    </div>
  );
};

export const ErrorPage = memo(ErrorPageComp);
