// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "auth.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Auth } from "./auth";
import type { GetDevCodeResponse } from "./auth";
import type { GetDevCodeRequest } from "./auth";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { TokenResponse } from "./auth";
import type { AuthenticateRequest } from "./auth";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Auth
 */
export interface IAuthClient {
    /**
     * @generated from protobuf rpc: Authenticate(AuthenticateRequest) returns (TokenResponse);
     */
    authenticate(input: AuthenticateRequest, options?: RpcOptions): UnaryCall<AuthenticateRequest, TokenResponse>;
    /**
     * @generated from protobuf rpc: GetDevCode(GetDevCodeRequest) returns (GetDevCodeResponse);
     */
    getDevCode(input: GetDevCodeRequest, options?: RpcOptions): UnaryCall<GetDevCodeRequest, GetDevCodeResponse>;
}
/**
 * @generated from protobuf service Auth
 */
export class AuthClient implements IAuthClient, ServiceInfo {
    typeName = Auth.typeName;
    methods = Auth.methods;
    options = Auth.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Authenticate(AuthenticateRequest) returns (TokenResponse);
     */
    authenticate(input: AuthenticateRequest, options?: RpcOptions): UnaryCall<AuthenticateRequest, TokenResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuthenticateRequest, TokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDevCode(GetDevCodeRequest) returns (GetDevCodeResponse);
     */
    getDevCode(input: GetDevCodeRequest, options?: RpcOptions): UnaryCall<GetDevCodeRequest, GetDevCodeResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDevCodeRequest, GetDevCodeResponse>("unary", this._transport, method, opt, input);
    }
}
