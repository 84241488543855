// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "locate.proto" (syntax proto3)
// tslint:disable
import { InitialCaseDataResponse } from "./commonTypes";
import { InitialCaseDataRequest } from "./commonTypes";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "./google/protobuf/wrappers";
import { CoordinatesDto } from "./commonTypes";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * @generated from protobuf message SaveLocationRequest
 */
export interface SaveLocationRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: CoordinatesDto coordinates = 2;
     */
    coordinates?: CoordinatesDto;
    /**
     * @generated from protobuf field: google.protobuf.StringValue phoneNumber = 3;
     */
    phoneNumber?: StringValue;
}
/**
 * @generated from protobuf message SaveLocationResponse
 */
export interface SaveLocationResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class SaveLocationRequest$Type extends MessageType<SaveLocationRequest> {
    constructor() {
        super("SaveLocationRequest", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "coordinates", kind: "message", T: () => CoordinatesDto },
            { no: 3, name: "phoneNumber", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<SaveLocationRequest>): SaveLocationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveLocationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveLocationRequest): SaveLocationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* CoordinatesDto coordinates */ 2:
                    message.coordinates = CoordinatesDto.internalBinaryRead(reader, reader.uint32(), options, message.coordinates);
                    break;
                case /* google.protobuf.StringValue phoneNumber */ 3:
                    message.phoneNumber = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.phoneNumber);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveLocationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* CoordinatesDto coordinates = 2; */
        if (message.coordinates)
            CoordinatesDto.internalBinaryWrite(message.coordinates, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue phoneNumber = 3; */
        if (message.phoneNumber)
            StringValue.internalBinaryWrite(message.phoneNumber, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SaveLocationRequest
 */
export const SaveLocationRequest = new SaveLocationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveLocationResponse$Type extends MessageType<SaveLocationResponse> {
    constructor() {
        super("SaveLocationResponse", []);
    }
    create(value?: PartialMessage<SaveLocationResponse>): SaveLocationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveLocationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveLocationResponse): SaveLocationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveLocationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SaveLocationResponse
 */
export const SaveLocationResponse = new SaveLocationResponse$Type();
/**
 * @generated ServiceType for protobuf service Locate
 */
export const Locate = new ServiceType("Locate", [
    { name: "SaveLocation", options: {}, I: SaveLocationRequest, O: SaveLocationResponse },
    { name: "GetInitialCaseData", options: {}, I: InitialCaseDataRequest, O: InitialCaseDataResponse }
]);
