import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { createContext, memo, useContext, useState } from 'react';
import { appConfig } from '../config/appConfig';
import { AuthClient } from './generated/auth.client';
import { LocateClient } from './generated/locate.client';
import { TrackClient } from './generated/track.client';

interface ApiClientContextProps {
  trackClient: TrackClient;
  locateClient: LocateClient;
  authClient: AuthClient;
}

const ApiClientContext = createContext<ApiClientContextProps>(undefined as never);
ApiClientContext.displayName = 'ApiClientContext';

export const useApiClient = () => {
  const ctx = useContext(ApiClientContext);

  if (!ctx) {
    throw new Error(`${ApiClientContext.displayName} must be provided before use`);
  }

  return ctx;
};

interface ApiClientProviderProps {
  children: React.ReactNode;
}

const ApiClientProviderComponent = ({ children }: ApiClientProviderProps) => {
  const [transport] = useState(
    new GrpcWebFetchTransport({
      baseUrl: appConfig.apiBaseURL,
    })
  );

  const [trackClient] = useState(() => new TrackClient(transport));
  const [locateClient] = useState(() => new LocateClient(transport));
  const [authClient] = useState(() => new AuthClient(transport));

  return (
    <ApiClientContext.Provider
      value={{
        trackClient,
        locateClient,
        authClient,
      }}
    >
      {children}
    </ApiClientContext.Provider>
  );
};

export const ApiClientProvider = memo(ApiClientProviderComponent);
