import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocateContext } from '@@locate/LocateContext';
import { useReverseGeocoding } from '@@map/useReverseGeocoding';
import { ConfirmLocation } from './ConfirmLocation';

const LocateBannerComp = () => {
  const { locationSent, selectedCoordinates, geoLocationRequestStatus, deviceGeoCoordinates } = useLocateContext();
  const { data: selectedAddress } = useReverseGeocoding(selectedCoordinates);
  const { t } = useTranslation();

  return (
    <div className="max-w-[100vw] bg-brand-primary text-brand-primary-contrast">
      <div className="container flex min-h-[6.5rem] max-w-4xl flex-col items-center gap-2 p-2 sm:min-h-[4rem] sm:flex-row sm:justify-center">
        {locationSent ? null : (
          <>
            {selectedAddress && <ConfirmLocation selectedAddress={selectedAddress} />}

            {!deviceGeoCoordinates && (
              <>
                <p className="max-w-full grow text-center text-lg sm:text-xl md:text-2xl">
                  {t(geoLocationRequestStatus)}
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const LocateBanner = memo(LocateBannerComp);
