import { memo } from 'react';
import { CaseResponse } from '@@api/generated/track';
import { Car, Hash } from '@@shared/icons';

interface CaseInformationProps {
  caseData: CaseResponse | undefined;
}
const CaseInformationComp = ({ caseData }: CaseInformationProps) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      {caseData?.visCaseId ? (
        <div className="flex items-center">
          <Hash className="h-8 w-8 pr-2 text-brand-primary" />
          {caseData?.visCaseId}
        </div>
      ) : null}
      {caseData?.registrationNumber ? (
        <div className="flex items-center">
          <Car className="h-8 w-8 pr-2 text-brand-primary" />
          {caseData?.registrationNumber.value}
        </div>
      ) : null}
    </div>
  );
};

export const CaseInformation = memo(CaseInformationComp);
