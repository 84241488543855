import { useCaseETAQuery } from '@@api/queries/trackClientQueries';

export const useEtaMessage = () => {
  const { data: etaResponseData } = useCaseETAQuery();
  const seconds = etaResponseData?.driverEta?.estimateInSeconds?.value ?? 0;
  const etaMessages = etaResponseData?.etaMessages;

  if (!etaMessages) {
    return null;
  }

  const minuteDifference = Math.floor(seconds / 60);
  const messages = etaMessages
    .sort((a, b) => b.minimumDuration - a.minimumDuration)
    .find((etaMessage) => etaMessage.minimumDuration <= minuteDifference);

  return messages?.message ?? null;
};
