import clsx from 'clsx';
import { ReactNode } from 'react';
import { vikingLogo, vikingLogoText } from '@@shared/images';

export const DefaultLayout = ({ brandLogo, children }: { brandLogo?: string; children: ReactNode }) => {
  return (
    <div className="grid h-full" style={{ gridTemplateRows: 'min-content auto' }}>
      <header className={clsx('h-12 border-b border-b-vk-beige-dark sm:h-16', !brandLogo && 'pl-4')}>
        <div className="relative h-full">
          <div className={clsx('flex h-full items-center', brandLogo ? 'justify-center' : 'justify-start')}>
            {brandLogo ? (
              <img className="block h-8" src={brandLogo} alt="logo" />
            ) : (
              <>
                <img className="block h-6 xs:hidden" src={vikingLogo} alt="logo" />
                <img className="hidden h-6 xs:block sm:h-8" src={vikingLogoText} alt="logo" />
              </>
            )}
          </div>
        </div>
      </header>
      <main className="relative">{children}</main>
    </div>
  );
};
