import { AuthenticationInterceptor } from '@@api/AuthenticationInterceptor';
import { getCodeFromUrl } from '@@config/appConfig';
import { useAuthenticationQuery } from './authenticationQueries';

export const useAuthentication = () => {
  const topSecret = getCodeFromUrl();

  const { data, isFetching, error } = useAuthenticationQuery(topSecret);

  const token = data?.token;

  const interceptor = new AuthenticationInterceptor(token);

  return { secret: topSecret, token, isFetching, error, interceptor };
};
