import { memo } from 'react';
import { useLocateInitialData } from '@@api/queries/locateClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Spinner } from '@@shared/base/Spinner';
import { useBranding } from '@@shared/hooks/useBranding';
import { useLanguage } from '@@shared/hooks/useLanguage';
import { DefaultLayout } from '@@shared/layouts/DefaultLayout';
import { LocateContextProvider } from './LocateContext';
import { LocateConcludedContainer } from './containers/LocateConcludedContainer';
import { LocatePageContainer } from './containers/LocatePageContainer';

const LocatePageComp = () => {
  const {
    isFetching: initialDataFetching,
    isFetched: isInitialDataFetched,
    data: initialCaseData,
  } = useLocateInitialData();
  const { brandLogo } = useBranding(initialCaseData, isInitialDataFetched);
  useLanguage(initialCaseData);

  if (initialDataFetching) {
    return <Spinner center isLoading />;
  }

  return (
    <LocateContextProvider>
      <ErrorBoundary>
        <DefaultLayout brandLogo={brandLogo}>
          {initialCaseData?.concluded ? <LocateConcludedContainer /> : <LocatePageContainer />}
        </DefaultLayout>
      </ErrorBoundary>
    </LocateContextProvider>
  );
};

export const LocatePage = memo(LocatePageComp);
