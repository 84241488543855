import { RpcOptions } from '@protobuf-ts/runtime-rpc';
import {
  MethodInfo,
  NextServerStreamingFn,
  NextUnaryFn,
  RpcInterceptor,
  ServerStreamingCall,
  UnaryCall,
} from '@@api/rpcExports';

export class AuthenticationInterceptor implements RpcInterceptor {
  private token: string | undefined;

  constructor(token: string | undefined) {
    this.token = token;
  }

  interceptUnary?(next: NextUnaryFn, method: MethodInfo, input: object, options: RpcOptions): UnaryCall {
    if (this.token) {
      if (!options.meta) {
        options.meta = {};
      }

      options.meta['Authorization'] = `Bearer ${this.token}`;
    }

    return next(method, input, options);
  }

  interceptServerStreaming?(
    next: NextServerStreamingFn,
    method: MethodInfo,
    input: object,
    options: RpcOptions
  ): ServerStreamingCall {
    if (this.token) {
      if (!options.meta) {
        options.meta = {};
      }

      options.meta['Authorization'] = `Bearer ${this.token}`;
    }

    return next(method, input, options);
  }
}
