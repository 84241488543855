export interface AppConfig {
  apiBaseURL: string;
  defaultLanguage: string;
  fallbackLanguage: string;
  releaseId: string;
  appInsightsInstrumentationKey: string;
  environment: string;
  googleMapsApiKey: string;
  localizationCdnUrl: string;
  appMode: AppMode;
  debug: boolean;
  devCodeBypassEnabled: boolean;
}

export type AppMode = 'track' | 'locate';

export let appConfig: AppConfig;

export const loadAppConfig = async (): Promise<AppConfig> => {
  const cacheBustHash = import.meta.env.VITE_CACHE_BUST_HASH ?? '';

  const file = await fetch(`/appConfig.${cacheBustHash ? cacheBustHash + '.' : ''}json`);
  const json = await file.json();

  appConfig = json as AppConfig;

  let key: keyof AppConfig;

  // Check for missing config items
  for (key in appConfig) {
    if (appConfig[key] === undefined) {
      console.error(`Could not load "${key}" from config file`);
    }
  }

  const isDebuggableEnvironment = import.meta.env.DEV || ['dev', 'qa'].includes(appConfig.environment.toLowerCase());

  const search = window.location.search.toLowerCase();
  const isDebugWanted = search.includes('debug=true') || (import.meta.env.DEV && !search.includes('debug=false'));

  appConfig.appMode = getAppModeFromUrl();
  appConfig.debug = isDebuggableEnvironment && isDebugWanted;
  appConfig.devCodeBypassEnabled = isDebuggableEnvironment;

  return appConfig;
};

export function getAppModeFromUrl() {
  const hostName = window.location.hostname.toLowerCase();

  return hostName.indexOf('track') > -1 ? 'track' : hostName.indexOf('locate') > -1 ? 'locate' : DEFAULT_APP_MODE;
}

export function getCodeFromUrl() {
  const code = window.location.pathname.substring(1, window.location.pathname.length) || undefined;

  return code;
}

const DEFAULT_APP_MODE: AppMode = 'locate';
