type CssColorVariables =
  | 'brand-primary'
  | 'brand-secondary'
  | 'brand-secondary-hover'
  | 'brand-primary-contrast'
  | 'brand-secondary-contrast'
  | 'default-dark'
  | 'default-light'
  | 'default-dark-secondary';

export const setCSSColorVariable = (variableName: CssColorVariables, colorCode: string) => {
  const root = document.documentElement;
  root.style.setProperty(`--color-${variableName}`, colorCode);
};

export const getCssColorVariable = (variableName: CssColorVariables) => {
  const root = document.documentElement;
  return window.getComputedStyle(root).getPropertyValue(`--color-${variableName}`);
};

const parse = (c: string) => parseInt(c, 16);

const getsRGB = (c: string) =>
  parse(c) / 255 <= 0.03928 ? parse(c) / 255 / 12.92 : Math.pow((parse(c) / 255 + 0.055) / 1.055, 2.4);

const getLuminance = (hexColor: string) =>
  0.2126 * getsRGB(hexColor.slice(1, 3)) +
  0.7152 * getsRGB(hexColor.slice(3, 5)) +
  0.0722 * getsRGB(hexColor.slice(-2));

const getContrast = (f: string, b: string) => {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

const getContrastColor = (baseColor: string, darkColor: string, lightColor: string) => {
  const whiteContrast = getContrast(baseColor, '#ffffff');
  const blackContrast = getContrast(baseColor, '#000000');
  return whiteContrast > blackContrast ? lightColor : darkColor;
};

export const setThemeColors = (primaryColor: string) => {
  const defaultDark = getCssColorVariable('default-dark');
  const defaultLight = getCssColorVariable('default-light');
  const defaultDarkSecondary = getCssColorVariable('default-dark-secondary');

  setCSSColorVariable('brand-primary', primaryColor);
  setCSSColorVariable('brand-primary-contrast', getContrastColor(primaryColor, defaultDark, defaultLight));

  const secondary = getContrastColor(primaryColor, defaultDarkSecondary, defaultLight);
  setCSSColorVariable('brand-secondary', secondary);
  setCSSColorVariable('brand-secondary-contrast', getContrastColor(secondary, defaultDark, defaultLight));
  setCSSColorVariable('brand-secondary-hover', getContrastColor(primaryColor, defaultLight, defaultDarkSecondary));
};

export const updateMetaThemeColor = () => {
  const themePrimaryColor = getCssColorVariable('brand-primary');

  const themeColorMetaEl = document.head?.querySelector('meta[name="theme-color"]');
  if (themeColorMetaEl) {
    themeColorMetaEl.setAttribute('content', themePrimaryColor);
  }
};
