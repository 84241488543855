import { CircleF, GoogleMap, MarkerF, PolylineF } from '@react-google-maps/api';
import { clsx } from 'clsx';
import { debounce, throttle } from 'lodash-es';
import { memo, ReactNode, useCallback, useRef, useState } from 'react';
import { useEffect, useMemo } from 'react';
import useEvent from 'react-use-event-hook';
import { appConfig } from '@@config/appConfig';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { crosshair, vikingPin } from '@@map/pins';
import { useIsTouchDevice } from '@@shared/hooks/useIsTouchDevice';
import { ZoomIn, ZoomOut } from '@@shared/icons';
import { MapsScriptLoader } from './MapsScriptLoader';
import { GIcon, GLatLng, GMap, GMarker, GPoint, GSize } from './googleTypes';
import { MAP_OPTIONS } from './mapOptions';
import { AppCircle, AppLatLng, AppMarker, AppPolyline } from './types';
import { toAppLatLng } from './utils';

export interface AppMapFacade {
  getCenter: () => AppLatLng | undefined;
  setCenter: (center: AppLatLng) => void;
  setZoom: (zoom: number) => void;
  fitBounds: (markers: AppLatLng[]) => void;
}

export interface AppMapControl {
  id: string;
  onClick: () => void;
  label: ReactNode;
}

interface Props {
  initCenter: AppLatLng;
  initZoom: number;
  markers?: AppMarker[];
  circles?: AppCircle[];
  centerMarkerEnabled?: boolean;
  onMapLoaded?: (facade: AppMapFacade) => void;
  onZoomChange?: (zoom: number) => void;
  customControls?: AppMapControl[];
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onIdle?: () => void;
  containerClassName?: string;
  showPegman?: boolean;
  polyline?: AppPolyline;
}

const AppMapComp = (props: Props) => {
  const { initCenter, initZoom, markers = [], circles = [], polyline } = props;
  const { centerMarkerEnabled, customControls } = props;
  const { onMapLoaded, onZoomChange, onDragStart, onDragEnd, onIdle } = props;
  const { containerClassName, showPegman } = props;

  const decodedPolylinePath = useMemo(
    () => (polyline ? google.maps.geometry.encoding.decodePath(polyline.encodedPath) : null),
    [polyline]
  );

  const [map, setMap] = useState<GMap | null>(null);

  const [centerGMarker, setCenterGMarker] = useState<GMarker | undefined>();

  const [center] = useState(() => initCenter);
  const [zoom] = useState(() => initZoom);

  const mapDivContainerRef = useRef<HTMLDivElement | null>(null);

  const onIdleEvent = useEvent(() => {
    onIdle && onIdle();
  });

  const [debouncedHandleIdle] = useState(() => debounce(onIdleEvent, 500));

  const isTouchDevice = useIsTouchDevice();

  const mapOptions = useMemo(() => {
    return {
      ...MAP_OPTIONS,
      streetViewControl: !!showPegman,
      keyboardShortcuts: !isTouchDevice,
    };
  }, [showPegman, isTouchDevice]);

  useEffect(() => {
    const divContainer = mapDivContainerRef.current;

    const eventCtrl = new AbortController();
    const eventOptions = { signal: eventCtrl.signal };

    if (divContainer && map) {
      // Implement custom even handler for mouse wheel to prevent
      // the map center from moving when zooming with the mouse wheel
      const tOnWheel = throttle((ev: WheelEvent) => {
        const scrolledUp = ev.deltaY < 0;
        map.setZoom((map.getZoom() ?? 0) + 1 * (scrolledUp ? 1 : -1));
      }, 250);

      divContainer.addEventListener('wheel', (ev: WheelEvent) => tOnWheel(ev), eventOptions);
    }

    return () => {
      eventCtrl.abort();
    };
  }, [map]);

  const handleLoad = useCallback(
    (map: GMap) => {
      setMap(map);

      const facade = {
        getCenter: () => toAppLatLng(map.getCenter()),
        setCenter: (center: AppLatLng) => map.setCenter({ lat: center.lat, lng: center.lng }),
        setZoom: (zoom: number) => map.setZoom(zoom),
        fitBounds: (markers: AppLatLng[]) => {
          const bounds = new window.google.maps.LatLngBounds();
          markers.forEach((location) => {
            bounds.extend(location);
          });
          map.fitBounds(bounds);
        },
      };

      onMapLoaded && onMapLoaded(facade);
    },
    [onMapLoaded]
  );

  const handleUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleCenterChanged = () => {
    const newCenter = map?.getCenter();

    if (!newCenter) return;

    const latLng = { lat: newCenter.lat(), lng: newCenter.lng() };

    centerGMarker && centerGMarker.setPosition(latLng);
  };

  const handleZoomChanged = () => {
    const newZoom = map?.getZoom();

    if (!newZoom) return;

    onZoomChange && onZoomChange(newZoom);
  };

  return (
    <div ref={mapDivContainerRef} className={clsx('relative', containerClassName)}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', minHeight: '100px', height: '100%' }}
        center={center}
        zoom={zoom}
        onLoad={handleLoad}
        onUnmount={handleUnmount}
        options={mapOptions}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onIdle={debouncedHandleIdle}
      >
        {centerMarkerEnabled && (
          <MarkerF
            // Position is controlled via setPosition()
            position={undefined as unknown as GLatLng}
            icon={centerDebugMarker}
            visible={!!centerMarkerEnabled && appConfig.debug}
            onLoad={setCenterGMarker}
          />
        )}

        {decodedPolylinePath && (
          <PolylineF path={decodedPolylinePath} options={polyline ? { strokeColor: polyline.color } : undefined} />
        )}

        {markers.map((m) => {
          return (
            <MarkerF
              key={`${m.id}`}
              position={{ lat: m.latitude, lng: m.longitude }}
              icon={
                m.icon && {
                  url: m.icon.url,
                  scaledSize: new google.maps.Size(m.icon.width, m.icon.height),
                  anchor: m.icon.isCircle
                    ? new google.maps.Point(m.icon.width / 2, m.icon.height / 2)
                    : new google.maps.Point(m.icon.width / 2, m.icon.height),
                }
              }
            />
          );
        })}

        {circles.map((c, index) => {
          return (
            <CircleF
              key={`${index}`}
              center={{ lat: c.latitude, lng: c.longitude }}
              radius={c.radius}
              options={{ fillColor: '#3498db', strokeColor: '#4687f4', strokeWeight: 1, fillOpacity: 0.1 }}
            />
          );
        })}
      </GoogleMap>

      <div className="absolute right-2.5 bottom-2">
        {customControls &&
          customControls.map((c) => {
            return (
              <button className="mapControlButton" title={c.id} key={c.id} onClick={c.onClick}>
                {c.label}
              </button>
            );
          })}
        <button className="mapControlButton" title="Zoom in" onClick={() => map?.setZoom((map.getZoom() ?? 0) + 1)}>
          <ZoomIn />
        </button>
        <button className="mapControlButton" title="Zoom out" onClick={() => map?.setZoom((map.getZoom() ?? 0) - 1)}>
          <ZoomOut />
        </button>
      </div>

      {centerMarkerEnabled && (
        <img
          alt="center marker"
          src={vikingPin}
          className="pointer-events-none absolute"
          style={{ height: '32px', width: '20px', left: 'calc(50% - 10px)', top: 'calc(50% - 32px)' }}
        />
      )}
    </div>
  );
};

const AppMapInner = memo(AppMapComp);

const AppMapWrapperComp = (props: Props) => {
  return (
    <ErrorBoundary>
      <MapsScriptLoader>
        <AppMapInner {...props} />
      </MapsScriptLoader>
    </ErrorBoundary>
  );
};

export const AppMap = memo(AppMapWrapperComp);

const centerDebugMarker: GIcon = {
  url: crosshair,
  scaledSize: { width: 64, height: 64 } as unknown as GSize,
  anchor: { x: 32, y: 32 } as unknown as GPoint,
};
