import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCase } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Card, CardTitle } from '@@shared/base/Card';
import { CaseInformation } from '@@track/components/CaseInformation';

const CaseInfoCardComp = () => {
  const { data: caseData, error: caseDataError } = useCase();
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle>{t('caseInfo')}</CardTitle>
      <ErrorBoundary error={caseDataError}>
        <CaseInformation caseData={caseData} />
      </ErrorBoundary>
    </Card>
  );
};

export const CaseInfoCard = memo(CaseInfoCardComp);
