import { memo, useCallback, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useApiErrorContext } from '@@api/ApiErrorContext';
import { Modal } from '@@shared/base/Modal';
import { XCircle } from '@@shared/icons';
import { ErrorPage } from './ErrorPage';

const ErrorPresenterComp = () => {
  const { error, setError } = useApiErrorContext();
  const { t } = useTranslation();

  const hideError = useCallback(() => {
    setError(null);
  }, [setError]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error?.severity === 'low') {
      toast.error(error?.errorTitle || t('tryAgainErrorMessage'), {
        icon: <XCircle className="h-10 w-10 pb-2 text-vk-red" />,
        duration: 10000,
        style: { maxWidth: '50rem' },
      });
      hideError();
    }
  }, [error, hideError, t]);

  return (
    <>
      <Modal show={!!error && error.severity === 'high'} cancelButton={t('close') || undefined} onCancel={hideError}>
        <ErrorPage error={error?.errorMessage} title={error?.errorTitle} isApiError />
      </Modal>
      <Toaster />
    </>
  );
};

export const ErrorPresenter = memo(ErrorPresenterComp);
