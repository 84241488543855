const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

const convertToClockMinutes = (minutes: number) => {
  if (minutes < 0) {
    return 0;
  }
  return Math.floor(minutes % MINUTES_IN_HOUR);
};
const convertToClockHours = (minutes: number) => {
  if (minutes < 0) {
    return 0;
  }
  return Math.floor(minutes / MINUTES_IN_HOUR);
};

const getMinutesWithInterval = (seconds: number, minutesInterval: number) => {
  const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
  return minutesInterval * Math.ceil(minutes / minutesInterval);
};

export const convertToClockTime = (seconds: number | undefined, minutesInterval: number) => {
  if (!seconds) {
    return undefined;
  }

  const minutes = getMinutesWithInterval(seconds, minutesInterval);
  return {
    hours: convertToClockHours(minutes),
    minutes: convertToClockMinutes(minutes),
  };
};
