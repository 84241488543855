// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "enums.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf enum CaseStatusEnum
 */
export enum CaseStatusEnum {
    /**
     * @generated from protobuf enum value: New = 0;
     */
    New = 0,
    /**
     * @generated from protobuf enum value: Registered = 1;
     */
    Registered = 1,
    /**
     * @generated from protobuf enum value: Assigned = 2;
     */
    Assigned = 2,
    /**
     * @generated from protobuf enum value: Underway = 3;
     */
    Underway = 3,
    /**
     * @generated from protobuf enum value: Arrival = 4;
     */
    Arrival = 4,
    /**
     * @generated from protobuf enum value: Loaded = 5;
     */
    Loaded = 5,
    /**
     * @generated from protobuf enum value: Completed = 98;
     */
    Completed = 98,
    /**
     * @generated from protobuf enum value: Cancelled = 99;
     */
    Cancelled = 99
}
/**
 * @generated from protobuf enum EstimateTypeEnum
 */
export enum EstimateTypeEnum {
    /**
     * @generated from protobuf enum value: InitialEstimate = 0;
     */
    InitialEstimate = 0,
    /**
     * @generated from protobuf enum value: ComputedEstimate = 1;
     */
    ComputedEstimate = 1
}
/**
 * @generated ServiceType for protobuf service EnumsPlaceholderThatFixesAutogeneratorBug
 */
export const EnumsPlaceholderThatFixesAutogeneratorBug = new ServiceType("EnumsPlaceholderThatFixesAutogeneratorBug", []);
