import { createContext, memo, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RpcError } from './rpcExports';

type ApiErrorSeverity = 'high' | 'low';

interface ApiRpcError {
  errorMessage: RpcError | null;
  errorTitle?: string;
  severity: ApiErrorSeverity;
}

interface ApiErrorContextProps {
  error: ApiRpcError | null;
  setError: (error: ApiRpcError | null) => void;
}
const ApiErrorContext = createContext<ApiErrorContextProps>(undefined as never);

export const useApiErrorContext = () => useContext(ApiErrorContext);

export const useApiError = (name: string, severity: ApiErrorSeverity = 'low') => {
  const { setError } = useApiErrorContext();
  const { t } = useTranslation();

  const onError = useCallback(
    (error: RpcError) => {
      setError({
        severity: severity,
        errorMessage: error,
        errorTitle: t('unableToFetch', { name: name.toLowerCase() }) ?? undefined,
      });
    },
    [setError, severity, name, t]
  );
  return { onError };
};

const ApiErrorContextProviderComp = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<ApiRpcError | null>(null);

  return (
    <ApiErrorContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {children}
    </ApiErrorContext.Provider>
  );
};

export const ApiErrorProvider = memo(ApiErrorContextProviderComp);
