import { useLayoutEffect, useState } from 'react';

export const useIsTouchDevice = () => useMediaQuery('(any-pointer:coarse');

export const useMediaQuery = (query: string): boolean => {
  const [match, setMatch] = useState(() => {
    return matchMedia(query).matches;
  });

  useLayoutEffect(() => {
    const queryList = matchMedia(query);

    const updateMatch = (ev: MediaQueryListEvent) => {
      setMatch(ev.matches);
    };

    queryList.addEventListener('change', updateMatch);

    return () => {
      queryList.removeEventListener('change', updateMatch);
    };
  }, [query]);

  return match;
};
