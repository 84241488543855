export const DEFAULT_MAP_ZOOM = 11;
export const DEFAULT_LOCK_ON_ZOOM = 16;
export const INCIDENT_DEFAULT_LOCK_ON_ZOOM = 14;

export const V_LANGUAGE_CODE_TO_ISO1: Record<string, string> = {
  N: 'nb',
  DK: 'da',
  S: 'sv',
  FI: 'fi',
  E: 'en',
};
