import { useTranslation } from 'react-i18next';
import { useApiClient } from '@@api/ApiClientContext';
import { useApiError } from '@@api/ApiErrorContext';
import { SaveLocationRequest } from '@@api/generated/locate';
import { useAuthentication } from '@@auth/useAuthentication';
import { useUnaryMutation, useUnaryQuery } from './useUnaryQuery';

export const useSaveLocationMutation = () => {
  const { locateClient } = useApiClient();
  const { interceptor } = useAuthentication();
  return useUnaryMutation((request: SaveLocationRequest) =>
    locateClient.saveLocation(request, { interceptors: [interceptor] })
  );
};

export const useLocateInitialData = () => {
  const { locateClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('caseInformation'));
  return useUnaryQuery(['InitialData'], () => locateClient.getInitialCaseData({}, { interceptors: [interceptor] }), {
    onError,
  });
};
