import { RpcError } from '@protobuf-ts/runtime-rpc';
import { DevCodeServiceType } from '@@api/generated/auth';
import { appConfig, getCodeFromUrl } from '@@config/appConfig';
import { useDevCodeQuery } from './authenticationQueries';

let redirecting = false;

export const useDevCodeBypass = (authError: RpcError | null) => {
  const topSecret = getCodeFromUrl();

  const bypassEnabled = !!authError && appConfig.devCodeBypassEnabled;

  const serviceType =
    appConfig.appMode === 'locate' ? DevCodeServiceType.LocateServiceType : DevCodeServiceType.TrackServiceType;

  const { data, isFetching, error } = useDevCodeQuery(topSecret, serviceType, bypassEnabled);

  const devAuthCode = data?.authCode;

  if (devAuthCode && !redirecting) {
    redirecting = true;
    window.document.write('Hacking in progress');
    setTimeout(() => {
      window.location.href = `${window.location.origin}/${devAuthCode}`;
    }, 1000);
  }

  return { devAuthCode, isFetching, error };
};
