import { RpcError, UnaryCall } from '@protobuf-ts/runtime-rpc';
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

/*
export declare function useQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey, 
  queryFn: QueryFunction<TQueryFnData, TQueryKey>, 
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>)
: UseQueryResult<TData, TError>;
*/
export function useUnaryQuery<TRequest extends object, TData extends object, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  func: () => UnaryCall<TRequest, TData>,
  options?: Omit<UseQueryOptions<TData, RpcError, TData, TQueryKey>, 'queryKey'>
): UseQueryResult<TData, RpcError> {
  return useQuery<TData, RpcError, TData, TQueryKey>(
    queryKey,
    async (): Promise<TData> => {
      const callResponse = await func();

      return callResponse.response;
    },
    options
  );
}

/*  
export declare function useMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>, 
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>)
: UseMutationResult<TData, TError, TVariables, TContext>; 
*/
export function useUnaryMutation<TRequest extends object, TData extends object>(
  func: (request: TRequest) => UnaryCall<TRequest, TData>,
  options?: Omit<UseMutationOptions<TData, RpcError, TRequest, unknown>, 'mutationFn'>
): UseMutationResult<TData, RpcError, TRequest, unknown> {
  return useMutation<TData, RpcError, TRequest, unknown>(async (request: TRequest): Promise<TData> => {
    const callResponse = await func(request);

    return callResponse.response;
  }, options);
}
