import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '@@auth/useAuthentication';
import { useDevCodeBypass } from '@@auth/useDevCodeBypass';
import { reactPlugin } from '@@config/appInsights';
import { ErrorPage } from '@@core/ErrorPage';
import { LocatePage } from '@@locate/LocatePage';
import { Spinner } from '@@shared/base/Spinner';
import { shouldBypassDevCodeError } from '@@shared/utils/error';
import { TrackPage } from '@@track/TrackPage';

interface Props {
  mode: 'track' | 'locate';
}

export function App(props: Props) {
  const { error: authError, isFetching: authIsFetching } = useAuthentication();
  const { error: bypassError, isFetching: bypassIsFetching } = useDevCodeBypass(authError);

  const { t } = useTranslation();

  const error = shouldBypassDevCodeError(bypassError) ? authError : bypassError || authError;

  if (authIsFetching) return <Spinner isLoading center />;

  if (authError) {
    if (bypassIsFetching) {
      return <Spinner isLoading center />;
    }

    return <ErrorPage error={error} fullPageError />;
  }

  return (
    <AppInsightsErrorBoundary
      onError={() => <h1>{`${t('tryAgainErrorMessage')} ${t('errorPersistMessage')}.`}</h1>}
      appInsights={reactPlugin}
    >
      <>{props.mode === 'locate' ? <LocatePage /> : <TrackPage />}</>
    </AppInsightsErrorBoundary>
  );
}
