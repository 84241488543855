import { clsx } from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

export const Card = (props: { children: ReactNode } & HTMLAttributes<HTMLDivElement>) => {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx('rounded-md bg-vk-white p-4 shadow-sm', className)} {...rest}>
      {children}
    </div>
  );
};

export const CardTitle = (props: { children: ReactNode } & HTMLAttributes<HTMLParagraphElement>) => {
  const { children, className, ...rest } = props;

  return (
    <p className={clsx('mb-2 text-center font-averta text-sm', className)} {...rest}>
      {children}
    </p>
  );
};
