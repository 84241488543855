import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStatusEnum } from '@@api/generated/enums';
import { useCase, useCaseStatusQuery } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Card, CardTitle } from '@@shared/base/Card';
import { Destination } from '@@track/components/Destination';

const ServiceLocationCardComp = () => {
  const { data: caseData, error: caseDataError } = useCase();
  const { data: caseStatusResponse } = useCaseStatusQuery();
  const isCompleted = caseStatusResponse?.caseStatus === CaseStatusEnum.Completed;

  const { t } = useTranslation();

  const serviceLocation = caseData?.serviceLocation;
  const showServiceLocation = !!serviceLocation?.name?.value?.length;

  const titleTranslationKey = isCompleted ? 'workshop' : 'plannedWorkshop';

  if (!showServiceLocation) {
    return null;
  }

  return (
    <Card>
      <CardTitle>{t(titleTranslationKey)}</CardTitle>
      <ErrorBoundary error={caseDataError}>
        <Destination
          name={serviceLocation.name?.value}
          address={serviceLocation.address?.value}
          postalCode={serviceLocation.postalCode?.value}
          postalTown={serviceLocation.postalTown?.value}
          phoneNumber={serviceLocation.telephoneNumber?.value}
        />
      </ErrorBoundary>
    </Card>
  );
};

export const ServiceLocationCard = memo(ServiceLocationCardComp);
