// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "track.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Track } from "./track";
import type { InitialCaseDataResponse } from "./commonTypes";
import type { InitialCaseDataRequest } from "./commonTypes";
import type { RouteResponse } from "./track";
import type { RouteRequest } from "./track";
import type { CoordinatesResponse } from "./track";
import type { CoordinatesRequest } from "./track";
import type { StatusResponse } from "./track";
import type { StatusRequest } from "./track";
import type { ETAResponse } from "./track";
import type { ETARequest } from "./track";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CaseResponse } from "./track";
import type { CaseRequest } from "./track";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Track
 */
export interface ITrackClient {
    /**
     * @generated from protobuf rpc: GetCase(CaseRequest) returns (CaseResponse);
     */
    getCase(input: CaseRequest, options?: RpcOptions): UnaryCall<CaseRequest, CaseResponse>;
    /**
     * @generated from protobuf rpc: GetETA(ETARequest) returns (stream ETAResponse);
     */
    getETA(input: ETARequest, options?: RpcOptions): ServerStreamingCall<ETARequest, ETAResponse>;
    /**
     * @generated from protobuf rpc: GetStatus(StatusRequest) returns (stream StatusResponse);
     */
    getStatus(input: StatusRequest, options?: RpcOptions): ServerStreamingCall<StatusRequest, StatusResponse>;
    /**
     * @generated from protobuf rpc: GetDriverCoordinates(CoordinatesRequest) returns (stream CoordinatesResponse);
     */
    getDriverCoordinates(input: CoordinatesRequest, options?: RpcOptions): ServerStreamingCall<CoordinatesRequest, CoordinatesResponse>;
    /**
     * @generated from protobuf rpc: GetIncidentCoordinates(CoordinatesRequest) returns (stream CoordinatesResponse);
     */
    getIncidentCoordinates(input: CoordinatesRequest, options?: RpcOptions): ServerStreamingCall<CoordinatesRequest, CoordinatesResponse>;
    /**
     * @generated from protobuf rpc: GetRoute(RouteRequest) returns (stream RouteResponse);
     */
    getRoute(input: RouteRequest, options?: RpcOptions): ServerStreamingCall<RouteRequest, RouteResponse>;
    /**
     * @generated from protobuf rpc: GetInitialCaseData(InitialCaseDataRequest) returns (InitialCaseDataResponse);
     */
    getInitialCaseData(input: InitialCaseDataRequest, options?: RpcOptions): UnaryCall<InitialCaseDataRequest, InitialCaseDataResponse>;
}
/**
 * @generated from protobuf service Track
 */
export class TrackClient implements ITrackClient, ServiceInfo {
    typeName = Track.typeName;
    methods = Track.methods;
    options = Track.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetCase(CaseRequest) returns (CaseResponse);
     */
    getCase(input: CaseRequest, options?: RpcOptions): UnaryCall<CaseRequest, CaseResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CaseRequest, CaseResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetETA(ETARequest) returns (stream ETAResponse);
     */
    getETA(input: ETARequest, options?: RpcOptions): ServerStreamingCall<ETARequest, ETAResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ETARequest, ETAResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStatus(StatusRequest) returns (stream StatusResponse);
     */
    getStatus(input: StatusRequest, options?: RpcOptions): ServerStreamingCall<StatusRequest, StatusResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<StatusRequest, StatusResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDriverCoordinates(CoordinatesRequest) returns (stream CoordinatesResponse);
     */
    getDriverCoordinates(input: CoordinatesRequest, options?: RpcOptions): ServerStreamingCall<CoordinatesRequest, CoordinatesResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CoordinatesRequest, CoordinatesResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetIncidentCoordinates(CoordinatesRequest) returns (stream CoordinatesResponse);
     */
    getIncidentCoordinates(input: CoordinatesRequest, options?: RpcOptions): ServerStreamingCall<CoordinatesRequest, CoordinatesResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<CoordinatesRequest, CoordinatesResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRoute(RouteRequest) returns (stream RouteResponse);
     */
    getRoute(input: RouteRequest, options?: RpcOptions): ServerStreamingCall<RouteRequest, RouteResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<RouteRequest, RouteResponse>("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInitialCaseData(InitialCaseDataRequest) returns (InitialCaseDataResponse);
     */
    getInitialCaseData(input: InitialCaseDataRequest, options?: RpcOptions): UnaryCall<InitialCaseDataRequest, InitialCaseDataResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialCaseDataRequest, InitialCaseDataResponse>("unary", this._transport, method, opt, input);
    }
}
