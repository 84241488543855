import { RpcError } from '@@api/rpcExports';

export const errorMessageCodes = {
  invalidAuthCode: 'InvalidAuthCode'.toLowerCase(),
  expiredAuthCode: 'ExpiredAuthCode'.toLowerCase(),
  devCodeNoMatch: 'DevCodeNoMatch'.toLowerCase(),
};

// Checks for a certain dev error that we don't want to show in the dev environments
export const shouldBypassDevCodeError = (error: RpcError | null) => {
  return error?.message.toLowerCase().includes(errorMessageCodes.devCodeNoMatch);
};
