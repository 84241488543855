import { clsx } from 'clsx';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStatusEnum } from '@@api/generated/enums';
import { Timestamp } from '@@api/generated/google/protobuf/timestamp';
import { StatusResponse } from '@@api/generated/track';
import { Check } from '@@shared/icons';
import { formatDateToHHMM } from '@@shared/utils/date';

interface CaseTimelineProps {
  caseStatusResponse: StatusResponse | undefined;
}

const CaseTimelineComp = (props: CaseTimelineProps) => {
  const { caseStatusResponse } = props;
  const { t } = useTranslation();

  const timelineItems = useMemo((): TimelineItem[] => {
    const statusHistory = caseStatusResponse?.statusHistory;
    const additionalItems = additionalTimelineItems.filter((i) =>
      statusHistory?.some((s) => s.caseStatus === i.statusId)
    );
    return [...defaultTimelineItems, ...additionalItems].map((timelineItem) => {
      const status = statusHistory?.find((s) => s.caseStatus === timelineItem.statusId);
      return { ...timelineItem, time: status?.timestamp ? Timestamp.toDate(status?.timestamp) : undefined };
    });
  }, [caseStatusResponse]);

  return (
    <div className="mt-4 pl-14">
      <ol className="relative border-l border-brand-primary">
        {timelineItems.map((item, index) => (
          <li key={index} className="mb-6 ml-4">
            <div className="absolute -left-2 flex h-4 w-4 items-center justify-center rounded-full bg-brand-primary">
              {!!item.time && <Check strokeWidth={3} className="h-3 w-3 text-vk-white" />}
            </div>
            <time className={clsx('absolute -left-14 text-sm leading-none', !!item.time && 'text-gray-500')}>
              {item.time ? formatDateToHHMM(item.time) : <span>&nbsp;</span>}
            </time>
            <p className={clsx('leading-none', { 'text-lg': !!item.time }, { 'text-md text-gray-500': !item.time })}>
              {t(item.textTranslationKey)}
            </p>
          </li>
        ))}
      </ol>
    </div>
  );
};

export const CaseTimeline = memo(CaseTimelineComp);

interface TimelineItem {
  time?: Date;
  textTranslationKey: string;
  statusId: CaseStatusEnum;
}

const additionalTimelineItems: TimelineItem[] = [
  {
    textTranslationKey: 'vehicleWasLoaded',
    statusId: CaseStatusEnum.Loaded,
  },
  {
    textTranslationKey: 'caseCompleted',
    statusId: CaseStatusEnum.Completed,
  },
];

const defaultTimelineItems: TimelineItem[] = [
  {
    textTranslationKey: 'caseRegistered',
    statusId: CaseStatusEnum.Registered,
  },
  {
    textTranslationKey: 'caseAssigned',
    statusId: CaseStatusEnum.Assigned,
  },
  {
    textTranslationKey: 'driverUnderway',
    statusId: CaseStatusEnum.Underway,
  },
  {
    textTranslationKey: 'driverHasArrived',
    statusId: CaseStatusEnum.Arrival,
  },
];
