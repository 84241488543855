import { clsx } from 'clsx';
import { ButtonHTMLAttributes, DetailedHTMLProps, memo, ReactNode } from 'react';

const ButtonComp = (
  props: { children: ReactNode; secondary?: boolean } & DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
) => {
  const { children, className, type, secondary, ...rest } = props;

  const defaultType = type ?? 'button';

  const colorStyles = !secondary
    ? 'bg-brand-secondary hover:bg-brand-secondary-hover'
    : 'border-2 hover:bg-opacity-10 hover:bg-vk-dark border-vk-dark';

  return (
    <button
      className={clsx(
        `whitespace-nowrap rounded-md px-4 py-2.5 font-averta text-lg text-brand-secondary-contrast disabled:bg-brand-secondary-hover disabled:text-gray-500`,
        colorStyles,
        className
      )}
      type={defaultType}
      {...rest}
    >
      {children}
    </button>
  );
};

export const Button = memo(ButtonComp);
