import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCaseStatusQuery } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Card, CardTitle } from '@@shared/base/Card';
import { CaseTimeline } from '@@track/components/CaseTimeline';

const CaseTimeLineCardComp = () => {
  const { data: caseStatusResponse, error: caseStatusError } = useCaseStatusQuery();
  const { t } = useTranslation();
  return (
    <Card>
      <CardTitle>{t('status')}</CardTitle>
      <ErrorBoundary error={caseStatusError}>
        <CaseTimeline caseStatusResponse={caseStatusResponse} />
      </ErrorBoundary>
    </Card>
  );
};

export const CaseTimeLineCard = memo(CaseTimeLineCardComp);
