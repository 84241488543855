import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocateContext } from '@@locate/LocateContext';
import { AppGeoCoordinates } from '@@map/types';
import { Modal } from '@@shared/base/Modal';
import { useEffectOnce } from '@@shared/hooks/useEffectOnce';
import { LocateFixed } from '@@shared/icons';

export const LocationAccess = () => {
  const { setDeviceGeoCoordinates, setGeoLocationRequestStatus, setSelectedCoordinates } = useLocateContext();

  const [showDeniedBrowserLocationModal, setShowDeniedBrowserLocationModal] = useState<boolean>(false);
  const [showAllowAccessLocationModal, setShowAllowAccessLocationModal] = useState<boolean>(false);

  const setDeviceCoordinates = (coordinates: AppGeoCoordinates) => {
    setDeviceGeoCoordinates(coordinates);
    setSelectedCoordinates(coordinates);
  };

  const { t } = useTranslation();

  const onDenyBrowserSettings = () => {
    setShowDeniedBrowserLocationModal(false);
  };
  const getPosition = () => {
    setShowAllowAccessLocationModal(false);
    setGeoLocationRequestStatus('pendingLocation');
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setShowAllowAccessLocationModal(false);
        setGeoLocationRequestStatus('successfulLocation');
        const appGeoCoords = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
          accuracy: pos.coords.accuracy,
        };
        setDeviceCoordinates(appGeoCoords);
      },
      (err) => {
        if (err.PERMISSION_DENIED) {
          setShowAllowAccessLocationModal(false);
          setShowDeniedBrowserLocationModal(true);
          setGeoLocationRequestStatus('deniedLocation');
        } else {
          setGeoLocationRequestStatus('errorLocation');
        }
      }
    );
  };

  useEffectOnce(() => {
    (async () => {
      const result = await navigator.permissions.query({ name: 'geolocation' });

      if (result.state === 'granted') {
        getPosition();
      } else {
        setShowAllowAccessLocationModal(true);
      }
    })();
  });

  return (
    <>
      <Modal
        show={showAllowAccessLocationModal}
        title={t('allowLocationAccess') || undefined}
        icon={<LocateFixed height={40} width={40} />}
        bottomModal
        onConfirm={getPosition}
        confirmButton={t('ok') || undefined}
        disableClickOutside
      >
        <div className="mt-2 mb-2">
          <p className="text-sm text-gray-500">{t('locationAccessStart')}</p>
        </div>
      </Modal>

      <Modal
        show={showDeniedBrowserLocationModal}
        title={t('changeLocationSettingInBrowser') || undefined}
        onCancel={onDenyBrowserSettings}
        cancelButton={t('ok') || undefined}
      >
        <div className="mt-2 mb-2">
          <p className="text-sm text-gray-500">
            {t('locationAccessDisabled')} {t('errorPersistMessage')}
          </p>
        </div>
      </Modal>
    </>
  );
};
