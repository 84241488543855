import { GMapOptions } from './googleTypes';

export const MAP_OPTIONS: GMapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  scrollwheel: false,
  styles: [
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e1e3e8',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
          color: '#d3d3d3',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
          color: '#b3d9ce',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#d7d7d7',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#9ecdd3',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [
        {
          color: '#3d5053',
        },
      ],
    },
    {
      featureType: 'transit.line.ferry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
};
