import { Transition } from '@headlessui/react';
import { memo, PropsWithChildren } from 'react';

interface SideSheetProps {
  show?: boolean;
}

const SideSheetComp = ({ show, children }: PropsWithChildren<SideSheetProps>) => {
  return (
    <Transition
      appear={true}
      show={!!show}
      enter="transition ease-in-out duration-500 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-500 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
      className={'absolute top-0 left-0 bottom-0 right-0 z-20 flex  w-full flex-col bg-vk-beige p-2'}
    >
      {children}
    </Transition>
  );
};

export const SideSheet = memo(SideSheetComp);
