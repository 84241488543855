import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleScriptLoader } from './useGoogleScriptLoader';

const MapsScriptLoaderComp = ({ children }: { children: ReactNode }) => {
  const { isLoaded } = useGoogleScriptLoader();
  const { t } = useTranslation();

  if (!isLoaded) return <p>{t('loading')}...</p>;

  return <>{children}</>;
};

export const MapsScriptLoader = memo(MapsScriptLoaderComp);
