import { Disclosure as HeadlessDisclosure } from '@headlessui/react';
import { memo, PropsWithChildren } from 'react';
import { ChevronDown, ChevronUp } from '@@shared/icons';

interface DisclosureProps {
  title: string;
}

const DisclosureComp = ({ title, children }: PropsWithChildren<DisclosureProps>) => {
  return (
    <HeadlessDisclosure>
      {({ open }) => (
        <>
          <HeadlessDisclosure.Button className="flex justify-between rounded-lg border-vk-dark px-4 py-2 text-left text-sm font-medium hover:bg-vk-dark hover:bg-opacity-10 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
            <span>{title}</span>
            {open ? <ChevronUp className={'h-5 w-5'} /> : <ChevronDown className={'h-5 w-5'} />}
          </HeadlessDisclosure.Button>
          <HeadlessDisclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {children}
          </HeadlessDisclosure.Panel>
        </>
      )}
    </HeadlessDisclosure>
  );
};
export const Disclosure = memo(DisclosureComp);
