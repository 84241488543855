import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ApiClientProvider } from '@@api/ApiClientContext';
import { ApiErrorProvider } from '@@api/ApiErrorContext';
import { ReactQueryProvider } from '@@api/queries/reactQueryProvider';
import { loadAppConfig } from '@@config/appConfig';
import { configureApplicationInsights } from '@@config/appInsights';
import { configureLocalization } from '@@config/localizationConfig';
import { ErrorPresenter } from '@@core/ErrorPresenter';
import { App } from './App';
import './styles/index.css';

(async function init() {
  const appConfig = await loadAppConfig();

  configureLocalization(appConfig);

  configureApplicationInsights(appConfig);

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <ReactQueryProvider>
        <ApiClientProvider>
          <ApiErrorProvider>
            <App mode={appConfig.appMode} />
            <ErrorPresenter />
          </ApiErrorProvider>
        </ApiClientProvider>
      </ReactQueryProvider>
    </StrictMode>
  );
})();
