import { memo } from 'react';
import { useCaseStatusQuery, useTrackInitialData } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Spinner } from '@@shared/base/Spinner';
import { useBranding } from '@@shared/hooks/useBranding';
import { useLanguage } from '@@shared/hooks/useLanguage';
import { DefaultLayout } from '@@shared/layouts/DefaultLayout';
import { TrackWithMapContainer } from './containers/TrackWithMapContainer';
import { TrackWithoutMapContainer } from './containers/TrackWithoutMapContainer';
import { noMapTrackStatuses } from './utils';

const TrackPageComp = () => {
  const { data: caseStatusResponse, isFetched: caseStatusIsFetched } = useCaseStatusQuery();
  const {
    isFetching: initialDataFetching,
    isFetched: isInitialCaseDataFetched,
    data: initialCaseData,
  } = useTrackInitialData();

  const { brandLogo } = useBranding(initialCaseData, isInitialCaseDataFetched);
  useLanguage(initialCaseData);

  const showNoMap =
    (caseStatusResponse?.caseStatus && noMapTrackStatuses.includes(caseStatusResponse.caseStatus)) ||
    initialCaseData?.concluded;

  if (initialDataFetching || !caseStatusIsFetched) {
    return <Spinner center isLoading />;
  }

  return (
    <ErrorBoundary>
      <DefaultLayout brandLogo={brandLogo}>
        {showNoMap ? <TrackWithoutMapContainer /> : <TrackWithMapContainer />}
      </DefaultLayout>
    </ErrorBoundary>
  );
};

export const TrackPage = memo(TrackPageComp);
