import { memo } from 'react';
import { CardContainerLayout } from '@@shared/layouts/CardContainerLayout';
import { CaseInfoCard } from '@@track/cards/CaseInfoCard';
import { CaseTimeLineCard } from '@@track/cards/CaseTimelineCard';
import { EtaCard } from '@@track/cards/EtaCard';
import { ServiceLocationCard } from '@@track/cards/ServiceLocationCard';
import { TackMapContainer } from '@@track/containers/TrackMapContainer';

const TrackWithMapContainerComp = () => (
  <>
    <TackMapContainer />
    <CardContainerLayout>
      <EtaCard />
      <CaseTimeLineCard />
      <ServiceLocationCard />
      <CaseInfoCard />
    </CardContainerLayout>
  </>
);

export const TrackWithMapContainer = memo(TrackWithMapContainerComp);
