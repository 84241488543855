import { memo } from 'react';
import { LocateBanner } from '@@locate/components/LocateBanner';
import { LocateMap } from '@@locate/components/LocateMap';
import { LocationAccess } from '@@locate/components/LocationAccess';
import { LocationSentSideSheet } from '@@locate/components/LocationSentSideSheet';

const LocatePageContainerComp = () => (
  <>
    <div className="grid h-full" style={{ gridTemplateRows: 'auto min-content' }}>
      <LocateMap />
      <LocationAccess />
      <LocateBanner />
    </div>
    <LocationSentSideSheet />
  </>
);

export const LocatePageContainer = memo(LocatePageContainerComp);
