import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocateContext } from '@@locate/LocateContext';
import { AppMap, AppMapControl, AppMapFacade } from '@@map/AppMap';
import { AppCircle, AppLatLng, AppMarker } from '@@map/types';
import { coordsAreEqual, toPreciseEnough } from '@@map/utils';
import { Locate, LocateFixed } from '@@shared/icons';
import { DebugOnly } from '@@shared/utils/DebugOnly';
import { DEFAULT_MAP_ZOOM, DEFAULT_LOCK_ON_ZOOM } from '@@shared/utils/constants';
import { buildAccuracyCircle, buildDeviceCoordsMarker } from '../utils';

const defaultMapCenter: AppLatLng = {
  lat: 59.93050287143177,
  lng: 10.711509612434527,
};

const LocateMapComp = () => {
  const { deviceGeoCoordinates, selectedCoordinates, setSelectedCoordinates } = useLocateContext();

  const [zoom, setZoom] = useState<number>(DEFAULT_MAP_ZOOM);

  const [facade, setFacade] = useState<AppMapFacade>();

  const [hasFocusedDevice, setHasFocusedDevice] = useState(false);

  const lockedOn = coordsAreEqual(selectedCoordinates, deviceGeoCoordinates);

  useEffect(() => {
    if (deviceGeoCoordinates && facade && !hasFocusedDevice) {
      setHasFocusedDevice(true);
      facade.setCenter(deviceGeoCoordinates);
      facade.setZoom(DEFAULT_LOCK_ON_ZOOM);
    }
  }, [deviceGeoCoordinates, facade, hasFocusedDevice]);

  const deviceCoordinatesMarker = useMemo<AppMarker | undefined>(
    () => buildDeviceCoordsMarker(deviceGeoCoordinates),
    [deviceGeoCoordinates]
  );

  const deviceGeoAccuracyCircle = useMemo<AppCircle | undefined>(
    () => buildAccuracyCircle(deviceGeoCoordinates),
    [deviceGeoCoordinates]
  );

  const appMapMarkers = useMemo(
    () => (deviceCoordinatesMarker ? [deviceCoordinatesMarker] : []),
    [deviceCoordinatesMarker]
  );

  const appCircles = useMemo(
    () => (deviceGeoAccuracyCircle ? [deviceGeoAccuracyCircle] : []),
    [deviceGeoAccuracyCircle]
  );

  const panToDeviceLocation = useCallback(() => {
    if (deviceGeoCoordinates && facade) {
      setSelectedCoordinates(deviceGeoCoordinates);
      facade.setCenter(deviceGeoCoordinates);
      facade.setZoom(DEFAULT_LOCK_ON_ZOOM);
    }
  }, [deviceGeoCoordinates, setSelectedCoordinates, facade]);

  const lockControl: AppMapControl = useMemo(
    () => ({
      id: 'lock',
      label: lockedOn ? <LocateFixed /> : <Locate />,
      onClick: () => {
        panToDeviceLocation();
      },
    }),
    [lockedOn, panToDeviceLocation]
  );

  const handleMapLoaded = (facade: AppMapFacade) => {
    setFacade(facade);
    // seems this needs to be reset
    setHasFocusedDevice(false);
  };

  const handleIdle = () => {
    if (!deviceGeoCoordinates) return;

    const newSelectedCoords = facade?.getCenter();

    if (newSelectedCoords) {
      setSelectedCoordinates(newSelectedCoords);
    }
  };

  const customControls: AppMapControl[] = useMemo(() => [lockControl], [lockControl]);

  return (
    <div className="relative h-full">
      <AppMap
        markers={appMapMarkers}
        circles={appCircles}
        initCenter={defaultMapCenter}
        initZoom={DEFAULT_MAP_ZOOM}
        onMapLoaded={handleMapLoaded}
        centerMarkerEnabled={!!deviceGeoCoordinates}
        onZoomChange={setZoom}
        customControls={customControls}
        onIdle={handleIdle}
        containerClassName="h-full"
        showPegman
      />
      <DebugOnly>
        <div className="absolute right-0 top-0 bg-gray-50 p-1 text-left text-xs">
          <p>
            Device coordinates{' '}
            <code className="float-right">
              {deviceGeoCoordinates
                ? `${toPreciseEnough(deviceGeoCoordinates.lat)}, ${toPreciseEnough(deviceGeoCoordinates.lng)}`
                : 'N/A'}
            </code>
          </p>
          <p>
            Selected coordinates{' '}
            <code className="float-right ml-2">
              {selectedCoordinates
                ? `${toPreciseEnough(selectedCoordinates.lat)}, ${toPreciseEnough(selectedCoordinates.lng)}`
                : 'N/A'}
            </code>
          </p>
          <p>Zoom level {zoom}</p>
          <p>Accuracy {deviceGeoCoordinates ? `${Math.round(deviceGeoCoordinates.accuracy)}m` : 'N/A'}</p>
          <button type="button" onClick={() => window.location.reload()}>
            Reload
          </button>
        </div>
      </DebugOnly>
    </div>
  );
};

export const LocateMap = memo(LocateMapComp);
