import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { Fragment, ReactNode } from 'react';
import { Button } from './Button';

interface ModalProps {
  show: boolean;
  title?: string;
  icon?: ReactNode;
  children?: ReactNode;
  bottomModal?: boolean;
  disableClickOutside?: boolean;
  confirmButton?: string;
  cancelButton?: string;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}

export const Modal = ({
  show,
  title,
  icon,
  children,
  bottomModal,
  disableClickOutside,
  confirmButton,
  cancelButton,
  onCancel,
  onConfirm,
}: ModalProps) => {
  const onClickOutside = () => {
    if (disableClickOutside) {
      return;
    }
    onCancel && onCancel();
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClickOutside}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div
              className={clsx(
                'flex min-h-full justify-center  text-center',
                bottomModal ? 'items-end' : 'items-center'
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    'w-full transform overflow-hidden bg-white p-6 text-center align-middle  shadow-xl transition-all',
                    { 'max-w-md rounded-2xl': !bottomModal }
                  )}
                >
                  {icon ? <div className="mb-4 flex justify-center ">{icon}</div> : null}
                  {title ? (
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                  ) : null}
                  {children}
                  {confirmButton ? (
                    <Button onClick={onConfirm} className="m-2 pr-8 pl-8">
                      {confirmButton}
                    </Button>
                  ) : null}
                  {cancelButton ? (
                    <Button onClick={onCancel} secondary className="m-2 pr-8 pl-8 ">
                      {cancelButton}
                    </Button>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
