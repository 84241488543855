import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCaseStatusQuery } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Card, CardTitle } from '@@shared/base/Card';
import { ETAWidget } from '@@track/components/ETAWidget';

const EtaCardComp = () => {
  const { data: caseStatusResponse, error: caseStatusError } = useCaseStatusQuery();
  const { t } = useTranslation();
  return (
    <Card>
      <CardTitle>{t('estimatedArrival')}</CardTitle>
      <ErrorBoundary error={caseStatusError}>
        <ETAWidget caseStatus={caseStatusResponse} />
      </ErrorBoundary>
    </Card>
  );
};

export const EtaCard = memo(EtaCardComp);
