import { useApiClient } from '@@api/ApiClientContext';
import { DevCodeServiceType } from '@@api/generated/auth';
import { useUnaryQuery } from '@@api/queries/useUnaryQuery';
import { appConfig } from '@@config/appConfig';

export const ERROR_MISSING_SECRET = 'missing secret';

export const useAuthenticationQuery = (topSecret: string | undefined) => {
  const { authClient } = useApiClient();

  return useUnaryQuery(
    ['Authentication', topSecret],
    () => {
      if (!topSecret) {
        throw new Error(ERROR_MISSING_SECRET, {});
      }

      return authClient.authenticate({ topSecret });
    },
    // No point in retrying if there's no secret argument
    // TODO: this is a bit weird. find a better implementation
    { retry: !topSecret || appConfig.devCodeBypassEnabled ? false : undefined, staleTime: Infinity }
  );
};

export const useDevCodeQuery = (
  topSecret: string | undefined,
  serviceType: DevCodeServiceType,
  bypassEnabled: boolean
) => {
  const { authClient } = useApiClient();

  return useUnaryQuery(
    ['DevCode', topSecret, serviceType],
    () => {
      return authClient.getDevCode({ topSecret: topSecret ?? '', serviceType });
    },
    { retry: false, enabled: bypassEnabled && !!topSecret }
  );
};
