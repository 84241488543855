import { memo } from 'react';
import { useGetRouteQuery, useDriverCoordinates, useIncidentCoordinates } from '@@api/queries/trackClientQueries';
import { AppLatLng } from '@@map/types';
import { apiLatitudeLongitudeToAppLatLng } from '@@map/utils';
import { TrackMap } from '../components/TrackMap';

const TackMapContainerComp = () => {
  const { data: routeResponse } = useGetRouteQuery();
  const { data: coordinatesResponse } = useDriverCoordinates();
  const { data: incidentCoordinatesResponse } = useIncidentCoordinates();

  const incidentLocation: AppLatLng | undefined = apiLatitudeLongitudeToAppLatLng(
    incidentCoordinatesResponse?.coordinates
  );
  const vikingCarLocation: AppLatLng | undefined = apiLatitudeLongitudeToAppLatLng(coordinatesResponse?.coordinates);
  const encodedPolyline = routeResponse?.caseRoute?.encodedPolyline;

  return (
    <TrackMap
      incidentLocation={incidentLocation}
      vikingCarLocation={vikingCarLocation}
      mapCenter={incidentLocation}
      encodedPolyline={encodedPolyline}
    />
  );
};
export const TackMapContainer = memo(TackMapContainerComp);
