import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EstimateTypeEnum } from '@@api/generated/enums';
import { useCaseETAQuery } from '@@api/queries/trackClientQueries';
import { convertToClockTime } from '@@shared/utils/convertToClockTime';

const THREE_HOURS_IN_SEC = 10_800;
const ONE_MINUTE_IN_SEC = 60;

export const useFormattedEtaText = () => {
  const { data: etaResponseData } = useCaseETAQuery();
  const isEtaComputed = etaResponseData?.driverEta?.etaType === EstimateTypeEnum.ComputedEstimate;
  const countdown = convertToClockTime(etaResponseData?.driverEta?.estimateInSeconds?.value, isEtaComputed ? 1 : 5);

  const etaSeconds = etaResponseData?.driverEta?.estimateInSeconds?.value ?? Infinity; // If time is not estimated set it to infinity (if that makes sense for the code below)
  const isOver3ClockHours = etaSeconds > THREE_HOURS_IN_SEC + ONE_MINUTE_IN_SEC; // 3 clock hours is up to  3:00:59 in this context, this is why we had to add the ONE_MINUTE_IN_SEC

  const { t } = useTranslation();

  const progressText = useMemo(() => {
    if (!countdown) {
      return '...';
    }
    const { hours, minutes } = countdown;
    if (hours === 0 && minutes === 0) {
      return t('asap');
    }
    if (hours > 0 && minutes === 0) {
      return `${hours} ${t('hour', { count: hours })} `;
    }
    return hours > 0 ? `${hours}${t('hourPostfix')} ${minutes}${t('minPostfix')}` : `${minutes} ${t('minShort')}`;
  }, [countdown, t]);

  const prefixText = useMemo(() => {
    if (isEtaComputed || !countdown) {
      return;
    }
    const { hours, minutes } = countdown;
    if (hours === 0 && minutes === 0) {
      return;
    }
    return 'ca';
  }, [countdown, isEtaComputed]);

  return {
    progressText,
    prefixText,
    isOver3ClockHours,
  };
};
