import { TFunction } from 'i18next';
import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CaseStatusEnum } from '@@api/generated/enums';
import { CaseStatusHistory } from '@@api/generated/track';
import { formatDateToHHMM, formatTimeStampToDate } from '@@shared/utils/date';

type StatusContent = {
  icon?: ReactNode;
  title: string;
  timeStampTitle: string;
};
interface CaseStatusProps {
  caseStatus?: CaseStatusHistory;
}

const getStatusTime = (caseStatus: CaseStatusHistory) => {
  const timeStamp = caseStatus.timestamp;
  if (!timeStamp) {
    return 'N/A';
  }
  return formatDateToHHMM(formatTimeStampToDate(timeStamp) ?? new Date());
};

const getCaseStatusContent = (caseStatus: CaseStatusHistory, t: TFunction): StatusContent | undefined => {
  switch (caseStatus.caseStatus) {
    case CaseStatusEnum.Arrival:
      return {
        icon: '👋',
        title: `${t('driverHasArrived')}!`,
        timeStampTitle: `${t('arrivedTime')}: ${getStatusTime(caseStatus)}`,
      };
    case CaseStatusEnum.Loaded:
      return {
        icon: '👆',
        title: `${t('driverToWorkshop')}`,
        timeStampTitle: `${t('vehicleWasLoaded')}: ${getStatusTime(caseStatus)}`,
      };
    case CaseStatusEnum.Completed:
      return {
        icon: '👏',
        title: `${t('caseCompleted')}!`,
        timeStampTitle: `${t('completedCaseTime')}: ${getStatusTime(caseStatus)}`,
      };
    case CaseStatusEnum.Cancelled:
      return {
        title: `${t('caseCancelled')}`,
        timeStampTitle: `${t('cancelledCaseTime')}: ${getStatusTime(caseStatus)}`,
      };

    default:
      return;
  }
};

const CaseStatusComp = ({ caseStatus }: CaseStatusProps) => {
  const { t } = useTranslation();

  if (!caseStatus) {
    return null;
  }

  const content = getCaseStatusContent(caseStatus, t);
  return (
    <div className="flex flex-col items-center justify-center">
      {content?.icon ? <div className="pb-6 text-4xl">{content?.icon}</div> : null}
      <div className="text-center text-2xl">{content?.title ?? null}</div>
      <div className="t pt-4 pb-2 text-base">{content?.timeStampTitle ?? null}</div>
    </div>
  );
};

export const CaseStatus = memo(CaseStatusComp);
