import { memo } from 'react';
import { CaseStatusEnum } from '@@api/generated/enums';
import { useCaseStatusQuery } from '@@api/queries/trackClientQueries';
import { CardContainerLayout } from '@@shared/layouts/CardContainerLayout';
import { CaseInfoCard } from '@@track/cards/CaseInfoCard';
import { CaseStatusCard } from '@@track/cards/CaseStatusCard';
import { CaseTimeLineCard } from '@@track/cards/CaseTimelineCard';
import { ServiceLocationCard } from '@@track/cards/ServiceLocationCard';

const TrackWithoutMapContainerComp = () => {
  const { data: caseStatusResponse } = useCaseStatusQuery();
  const isCancelled = caseStatusResponse?.caseStatus === CaseStatusEnum.Cancelled;
  return (
    <CardContainerLayout>
      <CaseStatusCard />
      {!isCancelled ? <CaseTimeLineCard /> : null}
      {!isCancelled ? <ServiceLocationCard /> : null}
      <CaseInfoCard />
    </CardContainerLayout>
  );
};

export const TrackWithoutMapContainer = memo(TrackWithoutMapContainerComp);
