import { RpcError } from '@protobuf-ts/runtime-rpc';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { DebugOnly } from '@@shared/utils/DebugOnly';
import { appInsights } from '../../config/appInsights';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof RpcError) {
        appInsights.trackException({ exception: error as RpcError });
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error instanceof RpcError) {
        appInsights.trackException({ exception: error as RpcError });
      }
    },
  }),
});

interface ReactQueryProviderProps {
  children: React.ReactNode;
}

const ReactQueryProviderComponent = ({ children }: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <DebugOnly>
        <ReactQueryDevtools />
      </DebugOnly>
    </QueryClientProvider>
  );
};

export const ReactQueryProvider = React.memo(ReactQueryProviderComponent);
