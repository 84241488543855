import clsx from 'clsx';

export interface ProgressShard {
  active?: boolean;
}

interface ProgressIndicatorProps {
  totalShards: number;
  activeShards?: number;
  prefix?: string;
  text?: string;
  invert?: boolean;
}

const textStyles = 'absolute left-[18px] w-16 text-center';

const svgHeightWidth = 100;
const strokeWidth = 6;

export const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const { totalShards, text, prefix, activeShards = 0, invert } = props;
  const shards = [...Array(totalShards).keys()];

  const cut = 100 / totalShards;
  const strokeDashoffset = 100 - cut;

  const calculateRotationDegree = (index: number) => {
    const circleCut = 360 / totalShards;
    const t = circleCut * index;
    return 270 + t;
  };

  const active = invert ? totalShards - activeShards : activeShards;

  return (
    <div className="relative">
      <svg width={svgHeightWidth} height={svgHeightWidth} viewBox={`0 0 ${svgHeightWidth} ${svgHeightWidth}`}>
        {shards.map((key, index) => (
          <CircleShard
            rotateDeg={calculateRotationDegree(index)}
            active={key < active}
            strokeDashoffset={strokeDashoffset}
            key={`circle-${key}`}
          />
        ))}
        <g>
          {shards.map((key, index) => (
            <SeparatorShard rotateDeg={calculateRotationDegree(index)} key={`separator-${key}`} />
          ))}
        </g>
      </svg>
      <div className={clsx(textStyles, 'top-5')}>{prefix}</div>
      <div
        className={clsx(
          textStyles,
          prefix ? 'top-10 ' : 'top-4 flex h-16 items-center justify-center',
          ' text-lg font-medium'
        )}
      >
        {text}
      </div>
    </div>
  );
};

const SeparatorShard = ({ rotateDeg }: { rotateDeg: number }) => (
  <rect
    width={strokeWidth + 2} // Add 2 to cover the curvatures
    height={strokeWidth - 2}
    x="90"
    y="48"
    fill="white"
    transform={`rotate(${rotateDeg})`}
    style={{ transformOrigin: `${svgHeightWidth / 2}px ${svgHeightWidth / 2}px` }}
  />
);

const CircleShard = ({
  active,
  rotateDeg,
  strokeDashoffset,
}: {
  active: boolean;
  rotateDeg: number;
  strokeDashoffset: number;
}) => (
  <circle
    cx={`${svgHeightWidth / 2}`}
    cy={`${svgHeightWidth / 2}`}
    r={`${svgHeightWidth / 2 - strokeWidth}`}
    fill="none"
    strokeWidth={strokeWidth}
    className="stroke-brand-primary"
    pathLength="100"
    style={{
      strokeDasharray: 100,
      strokeDashoffset,
      transformOrigin: `${svgHeightWidth / 2}px ${svgHeightWidth / 2}px`,
      strokeOpacity: active ? 1 : 0.3,
    }}
    transform={`rotate(${rotateDeg})`}
  />
);
