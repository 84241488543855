import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocateContext } from '@@locate/LocateContext';
import { Card } from '@@shared/base/Card';
import { SideSheet } from '@@shared/base/SideSheet';
import { CheckCircle } from '@@shared/icons';

const LocationSentSideSheetComp = () => {
  const { t } = useTranslation();
  const { locationSent } = useLocateContext();

  return (
    <SideSheet show={locationSent}>
      <Card>
        <div className="flex flex-col items-center justify-center p-4">
          <CheckCircle className="h-14 w-14 font-bold text-vk-green" />
          <h1 className="pt-6 text-xl">{t('thankYou')}</h1>
          <div className="pb-2 pt-2 text-center">
            <div>{t('locationSent')}</div>
            <div>{t('helpASAP')}</div>
          </div>
          <div className="pt-4">{t('youCanClosePage')}</div>
        </div>
      </Card>
    </SideSheet>
  );
};
export const LocationSentSideSheet = memo(LocationSentSideSheetComp);
