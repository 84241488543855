import { CoordinatesDto } from '@@api/generated/commonTypes';
import { GLatLng, GLatLngLiteral } from './googleTypes';
import { AppGeoCoordinates, AppLatLng } from './types';

export const toAppLatLng = (coords: GLatLng | undefined): AppLatLng | undefined => {
  if (!coords) return;

  return { lat: coords.lat(), lng: coords.lng() };
};

export const toGLatLng = (coords: AppLatLng | undefined): GLatLngLiteral | undefined => {
  if (!coords) return;

  return { lat: coords.lat, lng: coords.lng };
};

// Precision for working with map coordinates
// 4 fractional digits of precision = up to around 11 meters precision
// https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude/
export const toPreciseEnough = (number: number): string => {
  const magicNumber = 4;

  return number.toFixed(magicNumber);
};

export function coordsAreEqual(
  coords1: AppLatLng | AppGeoCoordinates | undefined,
  coords2: AppLatLng | AppGeoCoordinates | undefined
) {
  if ((coords1 && !coords2) || (!coords1 && coords2)) return false;

  if (!coords1 && !coords2) return true;

  const coords1LatLng: AppLatLng = coords1 as AppLatLng;
  const coords2LatLng: AppLatLng = coords2 as AppLatLng;

  return (
    toPreciseEnough(coords1LatLng.lat) === toPreciseEnough(coords2LatLng.lat) &&
    toPreciseEnough(coords1LatLng.lng) === toPreciseEnough(coords2LatLng.lng)
  );
}

export const apiLatitudeLongitudeToAppLatLng = (coordinates: CoordinatesDto | undefined): AppLatLng | undefined => {
  if (!coordinates) {
    return undefined;
  }
  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };
};
