import { PropsWithChildren, createContext, memo, useContext, useState } from 'react';
import { AppGeoCoordinates, AppLatLng } from '@@map/types';

export type GeolocationRequestStatus =
  | 'unknownLocation'
  | 'pendingLocation'
  | 'successfulLocation'
  | 'deniedLocation'
  | 'errorLocation';

type LocateContextProps = {
  deviceGeoCoordinates: AppGeoCoordinates | undefined;
  setDeviceGeoCoordinates: (coordinates: AppGeoCoordinates) => void;
  selectedCoordinates: AppLatLng | undefined;
  setSelectedCoordinates: (coordinates: AppLatLng | undefined) => void;
  geoLocationRequestStatus: GeolocationRequestStatus;
  setGeoLocationRequestStatus: (status: GeolocationRequestStatus) => void;
  locationSent: boolean;
  setLocationSent: (locationSent: boolean) => void;
};

export const LocateContext = createContext<LocateContextProps>(undefined as never);
export const useLocateContext = () => useContext(LocateContext);

const LocateContextProviderComp = ({ children }: PropsWithChildren) => {
  const [deviceGeoCoordinates, setDeviceGeoCoordinates] = useState<AppGeoCoordinates | undefined>();
  const [selectedCoordinates, setSelectedCoordinates] = useState<AppLatLng | undefined>();
  const [geoLocationRequestStatus, setGeoLocationRequestStatus] = useState<GeolocationRequestStatus>('unknownLocation');
  const [locationSent, setLocationSent] = useState<boolean>(false);

  return (
    <LocateContext.Provider
      value={{
        deviceGeoCoordinates,
        setDeviceGeoCoordinates,
        selectedCoordinates,
        setSelectedCoordinates,
        geoLocationRequestStatus,
        setGeoLocationRequestStatus,
        locationSent,
        setLocationSent,
      }}
    >
      {children}
    </LocateContext.Provider>
  );
};

export const LocateContextProvider = memo(LocateContextProviderComp);
