import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { memo, ReactNode } from 'react';
import { AppConfig } from './appConfig';

export const reactPlugin = new ReactPlugin();
export let appInsights: ApplicationInsights;

export function configureApplicationInsights(appConfig: AppConfig) {
  if (!appConfig.appInsightsInstrumentationKey && !import.meta.env.DEV) {
    console.warn('no app insights instrumentation key configured');
    return;
  }

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.appInsightsInstrumentationKey,
      extensions: [reactPlugin],
      loggingLevelConsole: 2,
      loggingLevelTelemetry: 2,
    },
  });
  appInsights.loadAppInsights();
}

const AppInsightsContextProvider = ({ children }: { children: ReactNode }) => {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};

export default memo(AppInsightsContextProvider);
