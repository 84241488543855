import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { memo, PropsWithChildren } from 'react';
import { reactPlugin } from '@@config/appInsights';
import { ErrorPage } from './ErrorPage';

interface ErrorBoundaryProps {
  error?: Error | RpcError | null;
}

const ErrorBoundaryComp = ({ children, error }: PropsWithChildren<ErrorBoundaryProps>) => {
  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <AppInsightsErrorBoundary onError={(error) => <ErrorPage error={error} />} appInsights={reactPlugin}>
      <>{children}</>
    </AppInsightsErrorBoundary>
  );
};

export const ErrorBoundary = memo(ErrorBoundaryComp);
