import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiErrorContext } from '@@api/ApiErrorContext';
import { SaveLocationRequest } from '@@api/generated/locate';
import { useSaveLocationMutation } from '@@api/queries/locateClientQueries';
import { useLocateContext } from '@@locate/LocateContext';
import { Button } from '@@shared/base/Button';
import { Spinner } from '@@shared/base/Spinner';
import { MapPin, Send } from '@@shared/icons';

interface ConfirmLocationProps {
  selectedAddress: string | undefined;
}

const ConfirmLocationComp = ({ selectedAddress }: ConfirmLocationProps) => {
  const { setLocationSent, selectedCoordinates } = useLocateContext();
  const { setError } = useApiErrorContext();
  const { mutate: saveLocation, isLoading: saveLocationLoading } = useSaveLocationMutation();

  const { t } = useTranslation();

  const handleOnConfirmClick = () => {
    if (!selectedCoordinates) return;

    const request: SaveLocationRequest = {
      coordinates: { latitude: selectedCoordinates.lat, longitude: selectedCoordinates.lng },
    };

    saveLocation(request, {
      onSuccess: () => {
        setLocationSent(true);
      },
      onError: (error) => {
        setError({ errorMessage: error, severity: 'high', errorTitle: t('errorConfirmLocation') ?? undefined });
      },
    });
  };

  return (
    <>
      <p className="max-w-full grow truncate text-lg sm:text-xl md:text-2xl">
        <MapPin className="inline" /> {selectedAddress}
      </p>
      <Button onClick={handleOnConfirmClick} className="flex" disabled={saveLocationLoading}>
        {t('confirmLocation')} {saveLocationLoading ? <Spinner isLoading inline /> : <Send className="ml-2" />}
      </Button>
    </>
  );
};

export const ConfirmLocation = memo(ConfirmLocationComp);
