// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "track.proto" (syntax proto3)
// tslint:disable
import { InitialCaseDataResponse } from "./commonTypes";
import { InitialCaseDataRequest } from "./commonTypes";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
import { EstimateTypeEnum } from "./enums";
import { Int32Value } from "./google/protobuf/wrappers";
import { CoordinatesDto } from "./commonTypes";
import { CaseStatusEnum } from "./enums";
import { StringValue } from "./google/protobuf/wrappers";
/**
 * @generated from protobuf message CaseRequest
 */
export interface CaseRequest {
}
/**
 * @generated from protobuf message CaseResponse
 */
export interface CaseResponse {
    /**
     * @generated from protobuf field: string visCaseId = 1;
     */
    visCaseId: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue insuranceProvider = 2;
     */
    insuranceProvider?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue registrationNumber = 3;
     */
    registrationNumber?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue vehicleMakeModel = 4;
     */
    vehicleMakeModel?: StringValue;
    /**
     * @generated from protobuf field: IncidentInformation incident = 5;
     */
    incident?: IncidentInformation;
    /**
     * @generated from protobuf field: ServiceLocation serviceLocation = 6;
     */
    serviceLocation?: ServiceLocation;
    /**
     * @generated from protobuf field: repeated CaseStatusHistory statusHistory = 7;
     */
    statusHistory: CaseStatusHistory[];
    /**
     * @generated from protobuf field: CaseStatusEnum caseStatus = 8;
     */
    caseStatus: CaseStatusEnum;
}
/**
 * @generated from protobuf message IncidentInformation
 */
export interface IncidentInformation {
    /**
     * @generated from protobuf field: google.protobuf.StringValue country = 1;
     */
    country?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue address = 2;
     */
    address?: StringValue;
    /**
     * @generated from protobuf field: CoordinatesDto coordinates = 3;
     */
    coordinates?: CoordinatesDto;
}
/**
 * @generated from protobuf message ServiceLocation
 */
export interface ServiceLocation {
    /**
     * @generated from protobuf field: google.protobuf.StringValue name = 1;
     */
    name?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue address = 2;
     */
    address?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue postalCode = 3;
     */
    postalCode?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue postalTown = 4;
     */
    postalTown?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue telephoneNumber = 5;
     */
    telephoneNumber?: StringValue;
    /**
     * @generated from protobuf field: CoordinatesDto coordinates = 6;
     */
    coordinates?: CoordinatesDto;
    /**
     * @generated from protobuf field: google.protobuf.StringValue countryName = 7;
     */
    countryName?: StringValue;
}
/**
 * @generated from protobuf message ETARequest
 */
export interface ETARequest {
}
/**
 * @generated from protobuf message ETAResponse
 */
export interface ETAResponse {
    /**
     * @generated from protobuf field: DriverEta driverEta = 1;
     */
    driverEta?: DriverEta;
    /**
     * @generated from protobuf field: repeated EtaMessageResponse etaMessages = 2;
     */
    etaMessages: EtaMessageResponse[];
}
/**
 * @generated from protobuf message EtaMessageResponse
 */
export interface EtaMessageResponse {
    /**
     * @generated from protobuf field: string message = 1;
     */
    message: string;
    /**
     * @generated from protobuf field: int32 minimumDuration = 2;
     */
    minimumDuration: number;
}
/**
 * @generated from protobuf message DriverEta
 */
export interface DriverEta {
    /**
     * @generated from protobuf field: google.protobuf.Int32Value estimateInSeconds = 1;
     */
    estimateInSeconds?: Int32Value;
    /**
     * @generated from protobuf field: EstimateTypeEnum etaType = 2;
     */
    etaType: EstimateTypeEnum;
}
/**
 * @generated from protobuf message StatusRequest
 */
export interface StatusRequest {
}
/**
 * @generated from protobuf message StatusResponse
 */
export interface StatusResponse {
    /**
     * @generated from protobuf field: repeated CaseStatusHistory statusHistory = 1;
     */
    statusHistory: CaseStatusHistory[];
    /**
     * @generated from protobuf field: CaseStatusEnum caseStatus = 2;
     */
    caseStatus: CaseStatusEnum;
}
/**
 * @generated from protobuf message CaseStatusHistory
 */
export interface CaseStatusHistory {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: CaseStatusEnum caseStatus = 2;
     */
    caseStatus: CaseStatusEnum;
}
/**
 * @generated from protobuf message CoordinatesRequest
 */
export interface CoordinatesRequest {
}
/**
 * @generated from protobuf message CoordinatesResponse
 */
export interface CoordinatesResponse {
    /**
     * @generated from protobuf field: CoordinatesDto coordinates = 2;
     */
    coordinates?: CoordinatesDto;
}
/**
 * @generated from protobuf message RouteRequest
 */
export interface RouteRequest {
}
/**
 * @generated from protobuf message RouteResponse
 */
export interface RouteResponse {
    /**
     * @generated from protobuf field: CaseRouteDto caseRoute = 1;
     */
    caseRoute?: CaseRouteDto;
}
/**
 * @generated from protobuf message CaseRouteDto
 */
export interface CaseRouteDto {
    /**
     * @generated from protobuf field: int32 distanceInMeters = 1;
     */
    distanceInMeters: number;
    /**
     * @generated from protobuf field: int32 durationInMinutes = 2;
     */
    durationInMinutes: number;
    /**
     * @generated from protobuf field: string encodedPolyline = 3;
     */
    encodedPolyline: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class CaseRequest$Type extends MessageType<CaseRequest> {
    constructor() {
        super("CaseRequest", []);
    }
    create(value?: PartialMessage<CaseRequest>): CaseRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaseRequest): CaseRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CaseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CaseRequest
 */
export const CaseRequest = new CaseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaseResponse$Type extends MessageType<CaseResponse> {
    constructor() {
        super("CaseResponse", [
            { no: 1, name: "visCaseId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "insuranceProvider", kind: "message", T: () => StringValue },
            { no: 3, name: "registrationNumber", kind: "message", T: () => StringValue },
            { no: 4, name: "vehicleMakeModel", kind: "message", T: () => StringValue },
            { no: 5, name: "incident", kind: "message", T: () => IncidentInformation },
            { no: 6, name: "serviceLocation", kind: "message", T: () => ServiceLocation },
            { no: 7, name: "statusHistory", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaseStatusHistory },
            { no: 8, name: "caseStatus", kind: "enum", T: () => ["CaseStatusEnum", CaseStatusEnum] }
        ]);
    }
    create(value?: PartialMessage<CaseResponse>): CaseResponse {
        const message = { visCaseId: "", statusHistory: [], caseStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaseResponse): CaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string visCaseId */ 1:
                    message.visCaseId = reader.string();
                    break;
                case /* google.protobuf.StringValue insuranceProvider */ 2:
                    message.insuranceProvider = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.insuranceProvider);
                    break;
                case /* google.protobuf.StringValue registrationNumber */ 3:
                    message.registrationNumber = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.registrationNumber);
                    break;
                case /* google.protobuf.StringValue vehicleMakeModel */ 4:
                    message.vehicleMakeModel = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.vehicleMakeModel);
                    break;
                case /* IncidentInformation incident */ 5:
                    message.incident = IncidentInformation.internalBinaryRead(reader, reader.uint32(), options, message.incident);
                    break;
                case /* ServiceLocation serviceLocation */ 6:
                    message.serviceLocation = ServiceLocation.internalBinaryRead(reader, reader.uint32(), options, message.serviceLocation);
                    break;
                case /* repeated CaseStatusHistory statusHistory */ 7:
                    message.statusHistory.push(CaseStatusHistory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* CaseStatusEnum caseStatus */ 8:
                    message.caseStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string visCaseId = 1; */
        if (message.visCaseId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.visCaseId);
        /* google.protobuf.StringValue insuranceProvider = 2; */
        if (message.insuranceProvider)
            StringValue.internalBinaryWrite(message.insuranceProvider, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue registrationNumber = 3; */
        if (message.registrationNumber)
            StringValue.internalBinaryWrite(message.registrationNumber, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue vehicleMakeModel = 4; */
        if (message.vehicleMakeModel)
            StringValue.internalBinaryWrite(message.vehicleMakeModel, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* IncidentInformation incident = 5; */
        if (message.incident)
            IncidentInformation.internalBinaryWrite(message.incident, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ServiceLocation serviceLocation = 6; */
        if (message.serviceLocation)
            ServiceLocation.internalBinaryWrite(message.serviceLocation, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated CaseStatusHistory statusHistory = 7; */
        for (let i = 0; i < message.statusHistory.length; i++)
            CaseStatusHistory.internalBinaryWrite(message.statusHistory[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* CaseStatusEnum caseStatus = 8; */
        if (message.caseStatus !== 0)
            writer.tag(8, WireType.Varint).int32(message.caseStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CaseResponse
 */
export const CaseResponse = new CaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncidentInformation$Type extends MessageType<IncidentInformation> {
    constructor() {
        super("IncidentInformation", [
            { no: 1, name: "country", kind: "message", T: () => StringValue },
            { no: 2, name: "address", kind: "message", T: () => StringValue },
            { no: 3, name: "coordinates", kind: "message", T: () => CoordinatesDto }
        ]);
    }
    create(value?: PartialMessage<IncidentInformation>): IncidentInformation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IncidentInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncidentInformation): IncidentInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue country */ 1:
                    message.country = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.country);
                    break;
                case /* google.protobuf.StringValue address */ 2:
                    message.address = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* CoordinatesDto coordinates */ 3:
                    message.coordinates = CoordinatesDto.internalBinaryRead(reader, reader.uint32(), options, message.coordinates);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncidentInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue country = 1; */
        if (message.country)
            StringValue.internalBinaryWrite(message.country, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue address = 2; */
        if (message.address)
            StringValue.internalBinaryWrite(message.address, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* CoordinatesDto coordinates = 3; */
        if (message.coordinates)
            CoordinatesDto.internalBinaryWrite(message.coordinates, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IncidentInformation
 */
export const IncidentInformation = new IncidentInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLocation$Type extends MessageType<ServiceLocation> {
    constructor() {
        super("ServiceLocation", [
            { no: 1, name: "name", kind: "message", T: () => StringValue },
            { no: 2, name: "address", kind: "message", T: () => StringValue },
            { no: 3, name: "postalCode", kind: "message", T: () => StringValue },
            { no: 4, name: "postalTown", kind: "message", T: () => StringValue },
            { no: 5, name: "telephoneNumber", kind: "message", T: () => StringValue },
            { no: 6, name: "coordinates", kind: "message", T: () => CoordinatesDto },
            { no: 7, name: "countryName", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<ServiceLocation>): ServiceLocation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ServiceLocation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLocation): ServiceLocation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue name */ 1:
                    message.name = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* google.protobuf.StringValue address */ 2:
                    message.address = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                case /* google.protobuf.StringValue postalCode */ 3:
                    message.postalCode = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.postalCode);
                    break;
                case /* google.protobuf.StringValue postalTown */ 4:
                    message.postalTown = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.postalTown);
                    break;
                case /* google.protobuf.StringValue telephoneNumber */ 5:
                    message.telephoneNumber = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.telephoneNumber);
                    break;
                case /* CoordinatesDto coordinates */ 6:
                    message.coordinates = CoordinatesDto.internalBinaryRead(reader, reader.uint32(), options, message.coordinates);
                    break;
                case /* google.protobuf.StringValue countryName */ 7:
                    message.countryName = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.countryName);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLocation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue name = 1; */
        if (message.name)
            StringValue.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue address = 2; */
        if (message.address)
            StringValue.internalBinaryWrite(message.address, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue postalCode = 3; */
        if (message.postalCode)
            StringValue.internalBinaryWrite(message.postalCode, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue postalTown = 4; */
        if (message.postalTown)
            StringValue.internalBinaryWrite(message.postalTown, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue telephoneNumber = 5; */
        if (message.telephoneNumber)
            StringValue.internalBinaryWrite(message.telephoneNumber, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* CoordinatesDto coordinates = 6; */
        if (message.coordinates)
            CoordinatesDto.internalBinaryWrite(message.coordinates, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue countryName = 7; */
        if (message.countryName)
            StringValue.internalBinaryWrite(message.countryName, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ServiceLocation
 */
export const ServiceLocation = new ServiceLocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ETARequest$Type extends MessageType<ETARequest> {
    constructor() {
        super("ETARequest", []);
    }
    create(value?: PartialMessage<ETARequest>): ETARequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ETARequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ETARequest): ETARequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ETARequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ETARequest
 */
export const ETARequest = new ETARequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ETAResponse$Type extends MessageType<ETAResponse> {
    constructor() {
        super("ETAResponse", [
            { no: 1, name: "driverEta", kind: "message", T: () => DriverEta },
            { no: 2, name: "etaMessages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EtaMessageResponse }
        ]);
    }
    create(value?: PartialMessage<ETAResponse>): ETAResponse {
        const message = { etaMessages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ETAResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ETAResponse): ETAResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* DriverEta driverEta */ 1:
                    message.driverEta = DriverEta.internalBinaryRead(reader, reader.uint32(), options, message.driverEta);
                    break;
                case /* repeated EtaMessageResponse etaMessages */ 2:
                    message.etaMessages.push(EtaMessageResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ETAResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* DriverEta driverEta = 1; */
        if (message.driverEta)
            DriverEta.internalBinaryWrite(message.driverEta, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated EtaMessageResponse etaMessages = 2; */
        for (let i = 0; i < message.etaMessages.length; i++)
            EtaMessageResponse.internalBinaryWrite(message.etaMessages[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ETAResponse
 */
export const ETAResponse = new ETAResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EtaMessageResponse$Type extends MessageType<EtaMessageResponse> {
    constructor() {
        super("EtaMessageResponse", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "minimumDuration", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EtaMessageResponse>): EtaMessageResponse {
        const message = { message: "", minimumDuration: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EtaMessageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EtaMessageResponse): EtaMessageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                case /* int32 minimumDuration */ 2:
                    message.minimumDuration = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EtaMessageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        /* int32 minimumDuration = 2; */
        if (message.minimumDuration !== 0)
            writer.tag(2, WireType.Varint).int32(message.minimumDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EtaMessageResponse
 */
export const EtaMessageResponse = new EtaMessageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DriverEta$Type extends MessageType<DriverEta> {
    constructor() {
        super("DriverEta", [
            { no: 1, name: "estimateInSeconds", kind: "message", T: () => Int32Value },
            { no: 2, name: "etaType", kind: "enum", T: () => ["EstimateTypeEnum", EstimateTypeEnum] }
        ]);
    }
    create(value?: PartialMessage<DriverEta>): DriverEta {
        const message = { etaType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DriverEta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DriverEta): DriverEta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Int32Value estimateInSeconds */ 1:
                    message.estimateInSeconds = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.estimateInSeconds);
                    break;
                case /* EstimateTypeEnum etaType */ 2:
                    message.etaType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DriverEta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Int32Value estimateInSeconds = 1; */
        if (message.estimateInSeconds)
            Int32Value.internalBinaryWrite(message.estimateInSeconds, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* EstimateTypeEnum etaType = 2; */
        if (message.etaType !== 0)
            writer.tag(2, WireType.Varint).int32(message.etaType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DriverEta
 */
export const DriverEta = new DriverEta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusRequest$Type extends MessageType<StatusRequest> {
    constructor() {
        super("StatusRequest", []);
    }
    create(value?: PartialMessage<StatusRequest>): StatusRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusRequest): StatusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StatusRequest
 */
export const StatusRequest = new StatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusResponse$Type extends MessageType<StatusResponse> {
    constructor() {
        super("StatusResponse", [
            { no: 1, name: "statusHistory", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaseStatusHistory },
            { no: 2, name: "caseStatus", kind: "enum", T: () => ["CaseStatusEnum", CaseStatusEnum] }
        ]);
    }
    create(value?: PartialMessage<StatusResponse>): StatusResponse {
        const message = { statusHistory: [], caseStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StatusResponse): StatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CaseStatusHistory statusHistory */ 1:
                    message.statusHistory.push(CaseStatusHistory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* CaseStatusEnum caseStatus */ 2:
                    message.caseStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated CaseStatusHistory statusHistory = 1; */
        for (let i = 0; i < message.statusHistory.length; i++)
            CaseStatusHistory.internalBinaryWrite(message.statusHistory[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* CaseStatusEnum caseStatus = 2; */
        if (message.caseStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.caseStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StatusResponse
 */
export const StatusResponse = new StatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaseStatusHistory$Type extends MessageType<CaseStatusHistory> {
    constructor() {
        super("CaseStatusHistory", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "caseStatus", kind: "enum", T: () => ["CaseStatusEnum", CaseStatusEnum] }
        ]);
    }
    create(value?: PartialMessage<CaseStatusHistory>): CaseStatusHistory {
        const message = { caseStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaseStatusHistory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaseStatusHistory): CaseStatusHistory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* CaseStatusEnum caseStatus */ 2:
                    message.caseStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaseStatusHistory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* CaseStatusEnum caseStatus = 2; */
        if (message.caseStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.caseStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CaseStatusHistory
 */
export const CaseStatusHistory = new CaseStatusHistory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CoordinatesRequest$Type extends MessageType<CoordinatesRequest> {
    constructor() {
        super("CoordinatesRequest", []);
    }
    create(value?: PartialMessage<CoordinatesRequest>): CoordinatesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CoordinatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CoordinatesRequest): CoordinatesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CoordinatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CoordinatesRequest
 */
export const CoordinatesRequest = new CoordinatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CoordinatesResponse$Type extends MessageType<CoordinatesResponse> {
    constructor() {
        super("CoordinatesResponse", [
            { no: 2, name: "coordinates", kind: "message", T: () => CoordinatesDto }
        ]);
    }
    create(value?: PartialMessage<CoordinatesResponse>): CoordinatesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CoordinatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CoordinatesResponse): CoordinatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CoordinatesDto coordinates */ 2:
                    message.coordinates = CoordinatesDto.internalBinaryRead(reader, reader.uint32(), options, message.coordinates);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CoordinatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* CoordinatesDto coordinates = 2; */
        if (message.coordinates)
            CoordinatesDto.internalBinaryWrite(message.coordinates, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CoordinatesResponse
 */
export const CoordinatesResponse = new CoordinatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteRequest$Type extends MessageType<RouteRequest> {
    constructor() {
        super("RouteRequest", []);
    }
    create(value?: PartialMessage<RouteRequest>): RouteRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RouteRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RouteRequest): RouteRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RouteRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RouteRequest
 */
export const RouteRequest = new RouteRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RouteResponse$Type extends MessageType<RouteResponse> {
    constructor() {
        super("RouteResponse", [
            { no: 1, name: "caseRoute", kind: "message", T: () => CaseRouteDto }
        ]);
    }
    create(value?: PartialMessage<RouteResponse>): RouteResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RouteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RouteResponse): RouteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CaseRouteDto caseRoute */ 1:
                    message.caseRoute = CaseRouteDto.internalBinaryRead(reader, reader.uint32(), options, message.caseRoute);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RouteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* CaseRouteDto caseRoute = 1; */
        if (message.caseRoute)
            CaseRouteDto.internalBinaryWrite(message.caseRoute, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RouteResponse
 */
export const RouteResponse = new RouteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaseRouteDto$Type extends MessageType<CaseRouteDto> {
    constructor() {
        super("CaseRouteDto", [
            { no: 1, name: "distanceInMeters", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "durationInMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "encodedPolyline", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CaseRouteDto>): CaseRouteDto {
        const message = { distanceInMeters: 0, durationInMinutes: 0, encodedPolyline: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaseRouteDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaseRouteDto): CaseRouteDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 distanceInMeters */ 1:
                    message.distanceInMeters = reader.int32();
                    break;
                case /* int32 durationInMinutes */ 2:
                    message.durationInMinutes = reader.int32();
                    break;
                case /* string encodedPolyline */ 3:
                    message.encodedPolyline = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaseRouteDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 distanceInMeters = 1; */
        if (message.distanceInMeters !== 0)
            writer.tag(1, WireType.Varint).int32(message.distanceInMeters);
        /* int32 durationInMinutes = 2; */
        if (message.durationInMinutes !== 0)
            writer.tag(2, WireType.Varint).int32(message.durationInMinutes);
        /* string encodedPolyline = 3; */
        if (message.encodedPolyline !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.encodedPolyline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CaseRouteDto
 */
export const CaseRouteDto = new CaseRouteDto$Type();
/**
 * @generated ServiceType for protobuf service Track
 */
export const Track = new ServiceType("Track", [
    { name: "GetCase", options: {}, I: CaseRequest, O: CaseResponse },
    { name: "GetETA", serverStreaming: true, options: {}, I: ETARequest, O: ETAResponse },
    { name: "GetStatus", serverStreaming: true, options: {}, I: StatusRequest, O: StatusResponse },
    { name: "GetDriverCoordinates", serverStreaming: true, options: {}, I: CoordinatesRequest, O: CoordinatesResponse },
    { name: "GetIncidentCoordinates", serverStreaming: true, options: {}, I: CoordinatesRequest, O: CoordinatesResponse },
    { name: "GetRoute", serverStreaming: true, options: {}, I: RouteRequest, O: RouteResponse },
    { name: "GetInitialCaseData", options: {}, I: InitialCaseDataRequest, O: InitialCaseDataResponse }
]);
