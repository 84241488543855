// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "locate.proto" (syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Locate } from "./locate";
import type { InitialCaseDataResponse } from "./commonTypes";
import type { InitialCaseDataRequest } from "./commonTypes";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { SaveLocationResponse } from "./locate";
import type { SaveLocationRequest } from "./locate";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service Locate
 */
export interface ILocateClient {
    /**
     * @generated from protobuf rpc: SaveLocation(SaveLocationRequest) returns (SaveLocationResponse);
     */
    saveLocation(input: SaveLocationRequest, options?: RpcOptions): UnaryCall<SaveLocationRequest, SaveLocationResponse>;
    /**
     * @generated from protobuf rpc: GetInitialCaseData(InitialCaseDataRequest) returns (InitialCaseDataResponse);
     */
    getInitialCaseData(input: InitialCaseDataRequest, options?: RpcOptions): UnaryCall<InitialCaseDataRequest, InitialCaseDataResponse>;
}
/**
 * @generated from protobuf service Locate
 */
export class LocateClient implements ILocateClient, ServiceInfo {
    typeName = Locate.typeName;
    methods = Locate.methods;
    options = Locate.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: SaveLocation(SaveLocationRequest) returns (SaveLocationResponse);
     */
    saveLocation(input: SaveLocationRequest, options?: RpcOptions): UnaryCall<SaveLocationRequest, SaveLocationResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveLocationRequest, SaveLocationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInitialCaseData(InitialCaseDataRequest) returns (InitialCaseDataResponse);
     */
    getInitialCaseData(input: InitialCaseDataRequest, options?: RpcOptions): UnaryCall<InitialCaseDataRequest, InitialCaseDataResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitialCaseDataRequest, InitialCaseDataResponse>("unary", this._transport, method, opt, input);
    }
}
