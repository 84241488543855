import { memo, useMemo } from 'react';
import { useCaseStatusQuery } from '@@api/queries/trackClientQueries';
import { ErrorBoundary } from '@@core/ErrorBoundary';
import { Card } from '@@shared/base/Card';
import { CaseStatus } from '@@track/components/CaseStatus';

const CaseStatusCardComp = () => {
  const { data: caseStatusResponse, error: caseStatusError } = useCaseStatusQuery();

  const currentCaseStatus = useMemo(
    () => caseStatusResponse?.statusHistory.find((s) => s.caseStatus === caseStatusResponse.caseStatus),
    [caseStatusResponse]
  );
  return (
    <Card className="mb-4">
      <ErrorBoundary error={caseStatusError}>
        <CaseStatus caseStatus={currentCaseStatus} />
      </ErrorBoundary>
    </Card>
  );
};

export const CaseStatusCard = memo(CaseStatusCardComp);
