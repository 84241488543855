// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "commonTypes.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "./google/protobuf/wrappers";
/**
 * @generated from protobuf message CoordinatesDto
 */
export interface CoordinatesDto {
    /**
     * @generated from protobuf field: double latitude = 1;
     */
    latitude: number;
    /**
     * @generated from protobuf field: double longitude = 2;
     */
    longitude: number;
}
/**
 * @generated from protobuf message InitialCaseDataResponse
 */
export interface InitialCaseDataResponse {
    /**
     * @generated from protobuf field: bool concluded = 1;
     */
    concluded: boolean;
    /**
     * @generated from protobuf field: google.protobuf.StringValue preferredLanguage = 2;
     */
    preferredLanguage?: StringValue;
    /**
     * @generated from protobuf field: BrandingAssets brandingAssets = 3;
     */
    brandingAssets?: BrandingAssets;
}
/**
 * @generated from protobuf message InitialCaseDataRequest
 */
export interface InitialCaseDataRequest {
}
/**
 * @generated from protobuf message BrandingAssets
 */
export interface BrandingAssets {
    /**
     * @generated from protobuf field: google.protobuf.StringValue brandLogo = 1;
     */
    brandLogo?: StringValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue brandColor = 2;
     */
    brandColor?: StringValue;
}
// @generated message type with reflection information, may provide speed optimized methods
class CoordinatesDto$Type extends MessageType<CoordinatesDto> {
    constructor() {
        super("CoordinatesDto", [
            { no: 1, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<CoordinatesDto>): CoordinatesDto {
        const message = { latitude: 0, longitude: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CoordinatesDto>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CoordinatesDto): CoordinatesDto {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double latitude */ 1:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 2:
                    message.longitude = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CoordinatesDto, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Bit64).double(message.latitude);
        /* double longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit64).double(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CoordinatesDto
 */
export const CoordinatesDto = new CoordinatesDto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialCaseDataResponse$Type extends MessageType<InitialCaseDataResponse> {
    constructor() {
        super("InitialCaseDataResponse", [
            { no: 1, name: "concluded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "preferredLanguage", kind: "message", T: () => StringValue },
            { no: 3, name: "brandingAssets", kind: "message", T: () => BrandingAssets }
        ]);
    }
    create(value?: PartialMessage<InitialCaseDataResponse>): InitialCaseDataResponse {
        const message = { concluded: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitialCaseDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialCaseDataResponse): InitialCaseDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool concluded */ 1:
                    message.concluded = reader.bool();
                    break;
                case /* google.protobuf.StringValue preferredLanguage */ 2:
                    message.preferredLanguage = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.preferredLanguage);
                    break;
                case /* BrandingAssets brandingAssets */ 3:
                    message.brandingAssets = BrandingAssets.internalBinaryRead(reader, reader.uint32(), options, message.brandingAssets);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialCaseDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool concluded = 1; */
        if (message.concluded !== false)
            writer.tag(1, WireType.Varint).bool(message.concluded);
        /* google.protobuf.StringValue preferredLanguage = 2; */
        if (message.preferredLanguage)
            StringValue.internalBinaryWrite(message.preferredLanguage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* BrandingAssets brandingAssets = 3; */
        if (message.brandingAssets)
            BrandingAssets.internalBinaryWrite(message.brandingAssets, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InitialCaseDataResponse
 */
export const InitialCaseDataResponse = new InitialCaseDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialCaseDataRequest$Type extends MessageType<InitialCaseDataRequest> {
    constructor() {
        super("InitialCaseDataRequest", []);
    }
    create(value?: PartialMessage<InitialCaseDataRequest>): InitialCaseDataRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitialCaseDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialCaseDataRequest): InitialCaseDataRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InitialCaseDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InitialCaseDataRequest
 */
export const InitialCaseDataRequest = new InitialCaseDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BrandingAssets$Type extends MessageType<BrandingAssets> {
    constructor() {
        super("BrandingAssets", [
            { no: 1, name: "brandLogo", kind: "message", T: () => StringValue },
            { no: 2, name: "brandColor", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<BrandingAssets>): BrandingAssets {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BrandingAssets>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BrandingAssets): BrandingAssets {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.StringValue brandLogo */ 1:
                    message.brandLogo = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.brandLogo);
                    break;
                case /* google.protobuf.StringValue brandColor */ 2:
                    message.brandColor = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.brandColor);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BrandingAssets, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.StringValue brandLogo = 1; */
        if (message.brandLogo)
            StringValue.internalBinaryWrite(message.brandLogo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue brandColor = 2; */
        if (message.brandColor)
            StringValue.internalBinaryWrite(message.brandColor, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BrandingAssets
 */
export const BrandingAssets = new BrandingAssets$Type();
/**
 * @generated ServiceType for protobuf service PlaceholderThatFixesAutogeneratorBug
 */
export const PlaceholderThatFixesAutogeneratorBug = new ServiceType("PlaceholderThatFixesAutogeneratorBug", []);
