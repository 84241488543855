import { useEffect, useMemo } from 'react';
import { InitialCaseDataResponse } from '@@api/generated/commonTypes';
import { setThemeColors, updateMetaThemeColor } from '@@shared/utils/style';

export const useBranding = (initialCaseData: InitialCaseDataResponse | undefined, isFetched: boolean) => {
  const brandColor = useMemo(() => initialCaseData?.brandingAssets?.brandColor?.value, [initialCaseData]);
  const brandLogo = useMemo(() => initialCaseData?.brandingAssets?.brandLogo?.value, [initialCaseData]);

  const hasBothColorAndLogo = !!brandColor && !!brandLogo;

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (hasBothColorAndLogo) {
      setThemeColors(brandColor);
    }

    updateMetaThemeColor();
  }, [brandColor, hasBothColorAndLogo, isFetched]);

  return {
    brandColor: hasBothColorAndLogo ? brandColor : undefined,
    brandLogo: hasBothColorAndLogo ? brandLogo : undefined,
  };
};
