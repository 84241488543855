import { memo } from 'react';
import { StatusResponse } from '@@api/generated/track';
import { ProgressIndicator } from '@@shared/base/ProgressIndicator';
import { Info } from '@@shared/icons';
import { useEtaMessage } from '@@track/hooks/useEtaMessage';
import { useFormattedEtaText } from '@@track/hooks/useFormattedEtaText';

interface ETAWidgetCompProps {
  caseStatus: StatusResponse | undefined;
}

const ETAWidgetComp = (props: ETAWidgetCompProps) => {
  const { caseStatus } = props;

  const etaMessage = useEtaMessage();
  const { progressText, prefixText, isOver3ClockHours } = useFormattedEtaText();

  return (
    <div>
      {isOver3ClockHours ? null : (
        <div className="flex justify-center pb-2">
          <ProgressIndicator
            totalShards={4}
            activeShards={caseStatus?.statusHistory.length}
            text={progressText}
            prefix={prefixText}
          />
        </div>
      )}

      {etaMessage ? (
        <div>
          <span className="flex">
            <Info className="mr-2 text-brand-primary" />
            {etaMessage}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export const ETAWidget = memo(ETAWidgetComp);
