import { useTranslation } from 'react-i18next';
import { useApiClient } from '@@api/ApiClientContext';
import { useApiError } from '@@api/ApiErrorContext';
import { useAuthentication } from '@@auth/useAuthentication';
import { useStreamingQuery } from './useStreamingQuery';
import { useUnaryQuery } from './useUnaryQuery';

export const useGetRouteQuery = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('driverRoute'));
  return useStreamingQuery(['CaseRoute'], () => trackClient.getRoute({}, { interceptors: [interceptor] }), {
    onError,
  });
};

export const useCaseStatusQuery = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('caseStatus'));
  return useStreamingQuery(['CaseStatus'], () => trackClient.getStatus({}, { interceptors: [interceptor] }), {
    onError,
  });
};

export const useCaseETAQuery = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('estimatedTimeArrival'));
  return useStreamingQuery(['ETA'], () => trackClient.getETA({}, { interceptors: [interceptor] }), {
    onError,
  });
};

export const useCase = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('caseInformation'));
  return useUnaryQuery(['Case'], () => trackClient.getCase({}, { interceptors: [interceptor] }), { onError });
};

export const useDriverCoordinates = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('driverCoordinates'));
  return useStreamingQuery(
    ['DriverCoordinates'],
    () => trackClient.getDriverCoordinates({}, { interceptors: [interceptor] }),
    {
      onError,
    }
  );
};

export const useIncidentCoordinates = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('incidentCoordinates'));
  return useStreamingQuery(
    ['IncidentCoordinates'],
    () => trackClient.getIncidentCoordinates({}, { interceptors: [interceptor] }),
    {
      onError,
    }
  );
};

export const useTrackInitialData = () => {
  const { trackClient } = useApiClient();
  const { interceptor } = useAuthentication();
  const { t } = useTranslation();
  const { onError } = useApiError(t('caseInformation'));
  return useUnaryQuery(['InitialData'], () => trackClient.getInitialCaseData({}, { interceptors: [interceptor] }), {
    onError,
  });
};
