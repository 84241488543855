// @generated by protobuf-ts 2.8.3 with parameter long_type_string
// @generated from protobuf file "auth.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message AuthenticateRequest
 */
export interface AuthenticateRequest {
    /**
     * random Generated Identifier
     *
     * @generated from protobuf field: string topSecret = 1;
     */
    topSecret: string;
}
/**
 * @generated from protobuf message TokenResponse
 */
export interface TokenResponse {
    /**
     * jwt contains the visCaseId
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message GetDevCodeRequest
 */
export interface GetDevCodeRequest {
    /**
     * @generated from protobuf field: string topSecret = 1;
     */
    topSecret: string;
    /**
     * @generated from protobuf field: DevCodeServiceType serviceType = 2;
     */
    serviceType: DevCodeServiceType;
}
/**
 * @generated from protobuf message GetDevCodeResponse
 */
export interface GetDevCodeResponse {
    /**
     * @generated from protobuf field: string authCode = 1;
     */
    authCode: string;
}
/**
 * @generated from protobuf enum DevCodeServiceType
 */
export enum DevCodeServiceType {
    /**
     * @generated from protobuf enum value: TrackServiceType = 0;
     */
    TrackServiceType = 0,
    /**
     * @generated from protobuf enum value: LocateServiceType = 1;
     */
    LocateServiceType = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class AuthenticateRequest$Type extends MessageType<AuthenticateRequest> {
    constructor() {
        super("AuthenticateRequest", [
            { no: 1, name: "topSecret", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthenticateRequest>): AuthenticateRequest {
        const message = { topSecret: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthenticateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthenticateRequest): AuthenticateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string topSecret */ 1:
                    message.topSecret = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthenticateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string topSecret = 1; */
        if (message.topSecret !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.topSecret);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AuthenticateRequest
 */
export const AuthenticateRequest = new AuthenticateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenResponse$Type extends MessageType<TokenResponse> {
    constructor() {
        super("TokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TokenResponse>): TokenResponse {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenResponse): TokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TokenResponse
 */
export const TokenResponse = new TokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDevCodeRequest$Type extends MessageType<GetDevCodeRequest> {
    constructor() {
        super("GetDevCodeRequest", [
            { no: 1, name: "topSecret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "serviceType", kind: "enum", T: () => ["DevCodeServiceType", DevCodeServiceType] }
        ]);
    }
    create(value?: PartialMessage<GetDevCodeRequest>): GetDevCodeRequest {
        const message = { topSecret: "", serviceType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDevCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDevCodeRequest): GetDevCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string topSecret */ 1:
                    message.topSecret = reader.string();
                    break;
                case /* DevCodeServiceType serviceType */ 2:
                    message.serviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDevCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string topSecret = 1; */
        if (message.topSecret !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.topSecret);
        /* DevCodeServiceType serviceType = 2; */
        if (message.serviceType !== 0)
            writer.tag(2, WireType.Varint).int32(message.serviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDevCodeRequest
 */
export const GetDevCodeRequest = new GetDevCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDevCodeResponse$Type extends MessageType<GetDevCodeResponse> {
    constructor() {
        super("GetDevCodeResponse", [
            { no: 1, name: "authCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDevCodeResponse>): GetDevCodeResponse {
        const message = { authCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDevCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDevCodeResponse): GetDevCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authCode */ 1:
                    message.authCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDevCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authCode = 1; */
        if (message.authCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetDevCodeResponse
 */
export const GetDevCodeResponse = new GetDevCodeResponse$Type();
/**
 * @generated ServiceType for protobuf service Auth
 */
export const Auth = new ServiceType("Auth", [
    { name: "Authenticate", options: {}, I: AuthenticateRequest, O: TokenResponse },
    { name: "GetDevCode", options: {}, I: GetDevCodeRequest, O: GetDevCodeResponse }
]);
