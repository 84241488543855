import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import { AppConfig } from './appConfig';
import * as sourceLocale from './sourceLocale.json';

export function configureLocalization(appConfig: AppConfig): void {
  i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      lng: appConfig.defaultLanguage,
      fallbackLng: appConfig.fallbackLanguage,
      supportedLngs: ['source', 'en', 'nb', 'sv', 'da', 'fi'],
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      backend: {
        backends: [
          resourcesToBackend({
            source: {
              translation: sourceLocale,
            },
          }),
          HttpBackend,
        ],
        backendOptions: [
          {},
          {
            loadPath: `${appConfig.localizationCdnUrl}/{{lng}}/{{ns}}.json`,
          },
        ],
      },
    });
}
