import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorPageCar } from '@@shared/images/ErrorPageCar.svg';

interface FullSizeErrorProps {
  code: number | string;
  title: string;
  description: string;
}

const FullSizeErrorComp = ({ code, title, description }: FullSizeErrorProps) => {
  const { t } = useTranslation();
  return (
    <div className="px-container scroll-anchor mx-auto max-w-[1500px] p-8 md:mb-0">
      <div className="mx-auto grid items-center sm:grid-cols-2 md:max-w-[80%]">
        <div className="msmd:max-w-xs -mb-[38vw] ml-auto w-[40%] sm:col-start-2 sm:mx-auto sm:mb-0 sm:w-auto sm:max-w-md">
          <ErrorPageCar />
        </div>
        <div className="sm:row-start-1">
          <h1 className="mb-7 text-[30vw] text-vk-red-medium sm:text-9xl">{code}</h1>
          <h2 className="mb-2 text-xl">{title}</h2>
          <p>{t('genericErrorMessage')}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export const FullSizeError = memo(FullSizeErrorComp);
