import { useJsApiLoader } from '@react-google-maps/api';
import { appConfig } from '@@config/appConfig';

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];

const libs: Libraries = ['geometry'];

export const useGoogleScriptLoader = () => {
  return useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: appConfig.googleMapsApiKey,
    libraries: libs,
  });
};
