import { useEffect, useRef } from 'react';

export const useEffectOnce = (effectFn: CallableFunction) => {
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    effectFn();
    // Doesn't matter if we ignore dependencies.
    // We want to function to be called only once anyway.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
